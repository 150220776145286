import * as yup from 'yup';

var currentYear = new Date().getFullYear();

const InitialprofileSchema = yup.object().shape({
  Yearestablishment: yup.number().required('Please Enter Year').min(1830, 'Year Must be minimum 1830 ').max(currentYear, `year Must be less than or equal to ${currentYear}`).typeError('Please Enter Year'),
  startup: yup.string(),
  CRN: yup.string().required('Please Enter Company Registration Number').
    min(3, 'Company Registration number must have minimum 3 characters.').max(70, 'The maximum length for the field is 70 characters.'), //matches(/^[a-z0-9]+$/i, 'only Alphanumeric characters are allowed.').
  isVat: yup.string(),
  // VATN : yup.string().matches(/^$|^[a-zA-Z0-9]+$/i, 'only Alphanumeric characters are allowed.').max(70, 'Company Registration number must have maximum 70 Characters.').nullable(),

  VATN: yup.string().when(
    "isVat", {
    is: '1',
    then: yup.string().required('Please Enter VAT Number').min(3, 'VAT number must have minimum 3 characters.').
      matches(/^[a-z0-9]+$/i, 'only Alphanumeric characters are allowed.').max(70, 'The maximum length for the field is 70 characters.'),
  }
  ),
  productBrand: yup.object().when("typeId", {
    is: '2', then: yup.object().when(
      "startup", {
      is: '1',
      then: yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required()
      }).required('Please Select a Product Brand').nullable()
    })
  }),

  multiProductBrand: yup.array().when("typeId", {
    is: '3' || '4', then: yup.array().of(yup.object().when(
      "startup", {
      is: '1',
      then: yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required()
      }).required('Please Select a Product Brand').nullable()
    }))
  }),



  Brands: yup.object().when("typeId", {
    is: '2' || '3',
    then: yup.object().shape({
      id: yup.number(),
      name: yup.string().required(),
      doc_id: yup.string(),
      documentpath: yup.string(),
      isNew: yup.string(),
    }).required('Choose a Brand').nullable()
  }),
  brandList: yup.string().when("typeId", { is: '2', then: yup.string().required('Please Choose a Brand') }),

  // ProductGroup:yup.array().when("typeId",{is:'3',then:yup.array().of(yup.object().shape({
  //   firstLetter: yup.string().required(),
  //   groupid: yup.string().required(),
  //   groupname: yup.string().required(),
  //   childid: yup.string().required(),
  //   childname: yup.string().required(),
  // })).required('Please Select a Product Family')}),

  // relevantProfiles:yup.array().when("typeId",{is:'4',then:yup.array().of(yup.object().shape({
  //   firstLetter: yup.string().required(),
  //   //groupid: yup.string().required(),
  //   groupname: yup.string().required(),
  //   childid: yup.string().required(),
  //   childname: yup.string().required(),
  // })).required('Please Select Channel Profile')}),
  relevantChannelProfiles: yup.string().when("typeId", { is: (typeId) => (typeId == '2') || (typeId == '3'), then: yup.string().required('Please Select Channel Profile') }),
  // relevantChannelProfiles:yup.string().when("typeId",{is:'3',then:yup.string().required('Please Select Channel Profile')}),
  productCat: yup.string().when("typeId", { is: '3', then: yup.string().when("productVal", { is: (val) => (val != 'broadliner'), then: yup.string().required('Please Select a Product Family') }) }),

  productCat: yup.string().required('Please Select Product Family'),

  relevantRetailerProfiles: yup.object().when("typeId", {
    is: '4', then: yup.object().shape({
      firstLetter: yup.string().required(),
      //groupid: yup.string().required(),
      groupname: yup.string().required(),
      childid: yup.string().required(),
      childname: yup.string().required(),

    }).required('Please Select Channel Profile')
  }),


  DistributorProfiles: yup.object().when("typeId", {
    is: '3', then: yup.object().shape({
      firstLetter: yup.string().required(),
      //groupid: yup.string().required(),
      groupname: yup.string().required(),
      childid: yup.string().required(),
      childname: yup.string().required(),
    }).required('Please Select Distributor Profile')
  }),

  no_of_staffs: yup.string().required('Select No.of.Staffs'),
  annualTurnOver: yup.string().required('Select Annual Turnover'),
  turnOver: yup.lazy(value => !value ? yup.string() : yup.number().min(0, 'Please enter non-negative values')),
  staffCnt: yup.lazy(value => !value ? yup.string() : yup.number().min(0, 'Please enter non-negative values')),
  tweetPitch: yup.string().required("TweetPitch can't be empty!").min(5, 'TweetPitch must have min 5 Characters').max(280, 'TweetPitch must have max 280 Characters'),
  //companyDescription: yup.string().required("Company Description can't be empty!").min(5, 'Company Description must have min 5 Characters').max(2000, 'Company Description must have max 2000 Characters'),
  //tradeCompanyDescription: yup.string().required("Trade Description can't be empty!").min(5, 'Trade Description must have min 5 Characters').max(2000, 'Trade Description must have max 2000 Characters'),
  no_of_skus: yup.number().when("typeId", { is: 2 || 3, then: yup.number().required('Please Enter No of SKU').min(0, 'Sku must have min 0').max(9999, 'sku must have max 4 digits').typeError('Please Enter No of SKU') }).typeError('Please Enter No of SKU'),
  companyLogo: yup.string().required('Please Select companyLogo'),

  tradeUrls: yup.array().of(yup.object({
    value: yup.lazy(value => !value ? yup.string() : yup.string()
      .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,63})?(\/.*)?$/, 'Please Enter Valid Url.'))
  })),
  insta: yup.lazy(value => !value ? yup.string() : yup.string().matches(/^(?=[a-zA-Z0-9._-]{0,30}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Instagram url can have 0-30 characters.')),
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  twitter: yup.lazy(value => !value ? yup.string() : yup.string().matches(/^(?=[a-zA-Z0-9._-]{0,15}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Twitter url can have 0-15 characters.')),
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  facebook: yup.lazy(value => !value ? yup.string() : yup.string().matches(/^(?=[a-zA-Z0-9._-]{0,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Facebook url can have 0-50 characters.')),
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  linkedIn: yup.lazy(value => !value ? yup.string() : yup.string().matches(/^(?=[a-zA-Z0-9._-]{0,100}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'LinkedIn url can have 0-100 characters.')),

});

const AddFirstProductSchema = yup.object().shape({
  proname: yup.string().required('Enter Product Name').min(3, 'Product Name must have Min 3 Characters').max(255, 'Product Name must have Max 255 Characters'),
  availability: yup.string(),
  noLimit: yup.boolean(),
  // productVisibility : yup.string().required('Please Choose visibility'),
  ProductCategory: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
    groupid: yup.string().required(),
  }).required('Choose Product Category').nullable(),
  ProductBrand: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required()
  }).required('Choose Product Brand').nullable(),

  // awards:yup.object().shape({
  //     id: yup.number().required(),
  //     name: yup.string().required()
  //   }).required('Choose awards').nullable(),
  videoUrl: yup.string().matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.').transform(value => !value ? null : value).nullable(), //.required('Please provide video Url.')
  tweetPitch: yup.string().required("TweetPitch can't be empty!").min(5, 'TweetPitch must have min 5 Characters').max(180, 'TweetPitch must have max 140 Characters'),
  productshortdec: yup.string().required("product Description can't be empty!").min(5, 'product Description must have min 5 Characters').max(140, 'product Description must have max 75 Characters'),
  productCertification: yup.string().max(255, 'Product Certification must have max 255 Characters'), //.required("Product Certification can't be empty!").min(5, 'Product Certification must have min 5 Characters')
  productWarranty: yup.string().max(255, 'Product Warranty must have max 255 Characters'), //.required("Product Warranty can't be empty!").min(5, 'Product Warranty must have min 5 Characters')
  productLocalisation: yup.string().max(255, 'Product Localisation must have max 255 Characters'), //.required("Product Localisation can't be empty!").min(5, 'Product Localisation must have min 5 Characters')
  // date: yup.string().when("availability", {
  //   is: 'Available From',
  //   then: yup.string().required('')
  //   }).required('Please Select a Date').nullable(),
  msrp: yup.number().required('Please enter MSRP.').typeError('MSRP must be a number'),
  moq: yup.string().required('Please enter MOQ.'),
  unit: yup.string().required('Please select unit.'),
  busCurrency: yup.string().required('Please select Business Currency.'),
  moqUnit: yup.string().required('Please select unit.'),
  max_qty_sample: yup.string().when("no_limit", {
    is: false,
    then: yup.string().required('Please enter Maximum Qty for Sample Orders.')
  }),
  date: yup.date().when('availability', {
    is: 'Available From',
    then: yup.date().required('Please Enter the Date.').typeError('Please Enter Valid Date.'),
  }),
  productType: yup.string().required('Please Select Product Type'),
  variantDetails: yup.string().when("productAttributes", { is: '1', then: yup.string().required('Please Enter Product Variant Details.') }),
  availableFromText: yup.string().when("availability", { is: 'Available From', then: yup.string().required('Please Enter available details') }),
});

const UserProfileSchema = yup.object().shape({

  selling: yup.string().when("typeId", { is: (typeId) => (typeId == 4 || typeId == 3), then: yup.string().required('Please Choose Selling Locations') }),
  target: yup.string().when("typeId", { is: (typeId) => (typeId == 2 || typeId == 3), then: yup.string().required('Please Choose Target Locations') }),


  productCat: yup.string().required('Please Select a Product'),

  CommunicationPreference1: yup.string().required('Please Select Communication Preference 1'),
  CommunicationPreference2: yup.string().required('Please Select Communication Preference 2'),
  companyLogo: yup.string().required('Please Select Profile Image.'),
});


const companyProfileSchema = yup.object().shape({
  Yearestablishment: yup.number().required('Please Enter Year').min(1830, 'Year Must be minimum 1830 ').max(currentYear, `year Must be less than or equal to ${currentYear}`).typeError('Please Enter Year'),
  companyLogo: yup.string().required('Please Select companyLogo'),
  companyName: yup.string().required('Please Enter Company Legal Name'),
  country: yup.string().required('Please Select Your country'),
  zipCode: yup.string().required('Please Enter Zip/Postal Code').min(3, 'Zip/Postal Code Must Have Min 3 Characters').max(12, 'Zip/Postal Code Must Have Max 12 Characters'),
  websiteUrl: yup.string().required('Please Enter Company Website URL').matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,63})?(\/.*)?$/, 'Please Enter Valid Url.'),
  tweetPitch: yup.string().required("TweetPitch can't be empty!").min(5, 'TweetPitch must have min 5 Characters').max(280, 'TweetPitch must have max 280 Characters'),//.matches(/^[A-Za-z]+$/, "Must be only alphabets"),
  sourcing: yup.string().when("typeId", { is: (typeId) => (typeId != '2'), then: yup.string().min(5, 'Sourcing Priority & Strategy must have min 5 Characters').max(5000, 'Sourcing Priority & Strategy must have max 5000 Characters') }),
  //companyDesc: yup.string().min(8, 'Company Description must have min 8 Characters').max(2000, 'The maximum length for the field is 2000 characters'),//.matches(/^[A-Za-z]+$/, "Must be only alphabets"),
  // CRN: yup
  //   .string()
  //   .trim()
  //   .required('Please Enter Company Registration Number')
  //   .min(3, 'Company Registration number must have minimum 3 characters.')
  //   .max(70, 'The maximum length for this field is 70 characters')
  //   .test('no-empty-spaces', 'Company Registration number must not contain empty spaces.', value => {
  //     if (value) {
  //       return /^\S+$/.test(value);
  //     }
  //     return false;
  //   }), 
    //matches(/^[a-z0-9]+$/i, 'only Alphanumeric characters are allowed.').
  // VATN : yup.string().matches(/^$|^[a-zA-Z0-9]+$/i, 'only Alphanumeric characters are allowed.').max(70, 'Company Registration number must have maximum 70 Characters.').nullable(),
  VATN: yup.string().when(
    "isVat", {
    is: '1',
    then: yup.string().required('Please Enter VAT Number').min(3, 'VAT number must have minimum 3 characters.').
      matches(/^[a-z0-9]+$/i, 'only Alphanumeric characters are allowed.').max(70, 'The maximum length for the field is 70 characters.'),
  }
  ),
  city: yup.string().required('Please Enter Your City.'),
  phoneNumber1: yup.string()
  .test('max-length', 'The maximum length for the field is 30 characters.', function(value) {
    // Check if the value is not empty and its length exceeds 30 characters
    return !(value && value.length > 30);
  })
  .test('min-length', 'Phone number must have minimum 5 characters.', function(value) {
    // Check if the value is not empty and its length is less than 5 characters
    return !(value && value.length > 0 && value.length < 5);
  })
  .test('valid-phone', 'Please Enter a valid Phone Number', function(value) {
    // Check if the value is not empty
    if (value && value.length > 0) {
      // Validate phone number pattern
      const isValidPattern = /^(?!(.*\s){4,})[0-9+ -]*[0-9]+[0-9+ -]*$/.test(value) &&
        /^(?:(?!([0-9])\1{5,}).)*$/.test(value);
      // Validate presence of non-special characters
      const hasNonSpecialChar = !/^[!@#$%^&*()_{}\[\]:;<>,.?~\\|\="']*$/.test(value);
      // Return true if both conditions are met
      return isValidPattern && hasNonSpecialChar;
    }
    // If value is empty or null, no custom validation needed
    return true;
  })
  .nullable(),
  // phoneNumber2:yup.string().min(5,'Phone number must have minimum 5 characters.').max(30,'Phone number must have maximum 30 Characters.').transform(value => !value ? null : value).nullable(), 
  mobile: yup.string().min(5, 'Phone number must have minimum 5 characters.').max(30, 'The maximum length for the field is 30 characters.').transform(value => !value ? null : value).nullable(),
  companyemail: yup.string().email('Please Enter a valid Email Address').transform(value => !value ? null : value).nullable(),

  insta: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'Instagram handle should have more than 2 characters').max(30, 'Instagram handle should have maximum of 30 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,30}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid Instagram Handle')),
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  twitter: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'Twitter handle should have more than 2 characters').max(15, 'Twitter handle should have maximum of 15 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,15}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid Twitter Handle')),
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  facebook: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'Facebook handle should have more than 2 characters').max(50, 'Facebook handle should have maximum of 50 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid Facebook Handle')),
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  linkedIn: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'LinkedIn handle should have more than 2 characters').max(100, 'LinkedIn handle should have maximum of 100 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,100}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid LinkedIn Handle')),

  tradeUrls: yup.array().of(yup.object({
    value: yup.lazy(value => !value ? yup.string() : yup.string()
      .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,63})?(\/.*)?$/, 'Please Enter Valid Url.'))
  })),

  brandList: yup.string().when("typeId", { is: (typeId) => (typeId == '2' || typeId == '3'), then: yup.string().required('Please Choose a Brand') }),



  productCat: yup.string().required('Please Select a Product Family'),

  no_of_skus: yup.number().when("typeId", { is: '2', then: yup.number().required('Please Enter No of SKUs').min(0, 'Sku must have min 0').max(9999, 'sku must have max 4 digits').typeError("Please Enter No of SKUs") }).nullable(),
  // storeCount: yup.number().when("typeId",{is:'4', then:yup.number().required("Please Enter No of Store's").min(0, 'No of store must have min 0').max(9999, 'sku must have max 4 digits').typeError("Please Enter No of Store's")}).nullable(),

  // revenue: yup.number().max(100, 'The total of Domestic Revenue and International Revenue should not exceed 100%').nullable(),

  no_of_staffs: yup.string().required('Select No.of.Staffs'),
  annualTurnOver: yup.string().required('Select Annual Turnover'),
  turnOver: yup.lazy(value => !value ? yup.string() : yup.number().min(0, 'Please enter non-negative values')),
  staffCnt: yup.lazy(value => !value ? yup.string() : yup.number().min(0, 'Please enter non-negative values')),

  // profile: yup.string().when("typeId",{is:'3',then:yup.string().required('Please Select a Product Distributor Profile')
  //     }),
  // speciality: yup.string().when("typeId", {is:'3', then: yup.string().when("profile", {is: "1", then: yup.string().required('Please Select Channel Speciality')})}),

  // relevantChannelProfiles:yup.string().when("typeId", {is: (typeId) => (typeId=='2' || typeId=='3'),then: yup.string().required('Please Select Channel Profile')}),

  // relevantRetailerProfiles:yup.object().when("typeId",{is:'4',then:yup.object().shape({
  //   // firstLetter: yup.string().required(),
  //   //groupid: yup.string().required(),
  //   groupname: yup.string().required(),
  //   // childid: yup.string().required(),
  //   childname: yup.string().required(),

  // }).required('Please Select Channel Profile')}),

});


const companyDistProfileSchema = yup.object().shape({
  Yearestablishment: yup.number().required('Please Enter Year').min(1830, 'Year Must be minimum 1830 ').max(currentYear, `year Must be less than or equal to ${currentYear}`).typeError('Please Enter Year'),
  companyLogo: yup.string().required('Please Select companyLogo'),
  companyName: yup.string().required('Please Enter Company Legal Name'),
  websiteUrl: yup.string().required('Please Enter Company Website URL').matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,63})?(\/.*)?$/, 'Please Enter Valid Url.'),
  tweetPitch: yup.string().required("TweetPitch can't be empty!").min(5, 'TweetPitch must have min 5 Characters').max(280, 'TweetPitch must have max 280 Characters'),//.matches(/^[A-Za-z]+$/, "Must be only alphabets"),
  sourcing: yup.string().when("typeId", { is: (typeId) => (typeId != '2'), then: yup.string().min(5, 'Sourcing Priority & Strategy must have min 5 Characters').max(5000, 'Sourcing Priority & Strategy must have max 5000 Characters') }),
  //companyDesc: yup.string().min(8, 'Company Description must have min 8 Characters').max(2000, 'The maximum length for the field is 2000 characters'),//.matches(/^[A-Za-z]+$/, "Must be only alphabets"),
  //CRN: yup.string().required('Please Enter Company Registration Number').
  //  min(3, 'Company Registration number must have minimum 3 characters.').max(70, 'The maximum length for the field is 70 characters.'), //matches(/^[a-z0-9]+$/i, 'only Alphanumeric characters are allowed.').
  // VATN : yup.string().matches(/^$|^[a-zA-Z0-9]+$/i, 'only Alphanumeric characters are allowed.').max(70, 'Company Registration number must have maximum 70 Characters.').nullable(),
  VATN: yup.string().when(
    "isVat", {
    is: '1',
    then: yup.lazy(value => !value ? yup.string() : yup.string().min(3, 'VAT number must have minimum 3 characters.').
      matches(/^[a-z0-9]+$/i, 'only Alphanumeric characters are allowed.').max(70, 'The maximum length for the field is 70 characters.')),
    //  yup.string().required('Please Enter VAT Number').min(3, 'VAT number must have minimum 3 characters.').
    // matches(/^[a-z0-9]+$/i, 'only Alphanumeric characters are allowed.').max(70, 'Company Registration number must have maximum 70 Characters.'),
  }
  ),

  phoneNumber1: yup.string()
  .test('max-length', 'The maximum length for the field is 30 characters.', function(value) {
    // Check if the value is not empty and its length exceeds 30 characters
    return !(value && value.length > 30);
  })
  .test('min-length', 'Phone number must have minimum 5 characters.', function(value) {
    // Check if the value is not empty and its length is less than 5 characters
    return !(value && value.length > 0 && value.length < 5);
  })
  .test('valid-phone', 'Please Enter a valid Phone Number', function(value) {
    // Check if the value is not empty
    if (value && value.length > 0) {
      // Validate phone number pattern
      const isValidPattern = /^(?!(.*\s){4,})[0-9+ -]*[0-9]+[0-9+ -]*$/.test(value) &&
        /^(?:(?!([0-9])\1{5,}).)*$/.test(value);
      // Validate presence of non-special characters
      const hasNonSpecialChar = !/^[!@#$%^&*()_{}\[\]:;<>,.?~\\|\="']*$/.test(value);
      // Return true if both conditions are met
      return isValidPattern && hasNonSpecialChar;
    }
    // If value is empty or null, no custom validation needed
    return true;
  })
  .nullable(), 
  mobile: yup.string().min(5, 'Phone number must have minimum 5 characters.').max(30, 'The maximum length for the field is 30 characters.').transform(value => !value ? null : value).nullable(),
  email: yup.string().email('Please Enter a valid Email Address').transform(value => !value ? null : value).nullable(),

  insta: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'Instagram handle should have more than 2 characters').max(30, 'Instagram handle should have maximum of 30 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,30}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid Instagram Handle')),
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  twitter: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'Twitter handle should have more than 2 characters').max(15, 'Twitter handle should have maximum of 15 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,15}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid Twitter Handle')),
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  facebook: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'Facebook handle should have more than 2 characters').max(50, 'Facebook handle should have maximum of 50 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid Facebook Handle')),
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  linkedIn: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'LinkedIn handle should have more than 2 characters').max(100, 'LinkedIn handle should have maximum of 100 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,100}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid LinkedIn Handle')),
  tradeUrls: yup.array().of(yup.object({
    value: yup.lazy(value => !value ? yup.string() : yup.string()
      .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,63})?(\/.*)?$/, 'Please Enter Valid Url.'))
  })),

  // websiteUrl: yup.array().of(yup.object({
  //   value : yup.lazy(value => !value ? yup.string() : yup.string()
  //   .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.'))
  // })),


  productCat: yup.string().required('Please Select a Product Family'),
  brandList: yup.string().when("typeId", { is: (typeId) => (typeId == '2' || typeId == '3'), then: yup.string().required('Please Choose a Brand') }),




  no_of_staffs: yup.string().required('Select No.of.Staffs'),
  annualTurnOver: yup.string().required('Select Annual Turnover'),

  turnOver: yup.lazy(value => !value ? yup.string() : yup.number().min(0, 'Please enter non-negative values')),
  staffCnt: yup.lazy(value => !value ? yup.string() : yup.number().min(0, 'Please enter non-negative values')),

  relevantChannelProfiles: yup.string().when("isOEM", { is: (typeId) => (typeId == '1' || typeId == '3'), then: yup.string().required('Please Select Retailer Profile') }),
  relevantResellerProfiles: yup.string().when("isOEM", { is: (typeId) => (typeId == '2' || typeId == '3'), then: yup.string().required('Please Select Reseller Profile') }),


  no_of_retailers: yup.number().when("businessType", { is: (typeId) => (typeId == '1' || typeId == '3'), then: yup.number().required('Please Enter No of Retailers').min(0, 'Retailer must have min 0').typeError('Please Enter No of Retailers') }),
  no_of_resellers: yup.number().when("businessType", { is: (typeId) => (typeId == '2' || typeId == '3'), then: yup.number().required('Please Enter No of Resellers').min(0, 'Reseller must have min 0').typeError('Please Enter No of Resellers') }),

  // no_of_resellers: yup.number().required('Please Enter No of Resellers').min(0, 'Distributor must have min 0').typeError('Please Enter No of Resellers'),
  // no_of_retailers: yup.number().required('Please Enter No of Retailers').min(0, 'Retailer must have min 0').typeError('Please Enter No of Retailers'),
  startup: yup.string(),
  productBrand: yup.string().when("startup", { is: '1', then: yup.string().required('select maturity level') }),

  DistributorProfiles: yup.string().when("typeId", { is: '3', then: yup.string().required('Please Select Channel Speciality') }),

  speciality: yup.string().when("typeId", { is: '3', then: yup.string().when("profile", { is: "1", then: yup.string().required('Please Select Channel Speciality') }) }),

  selling: yup.string().when("typeId", { is: (typeId) => (typeId == 4 || typeId == 3 || typeId == 5), then: yup.string().required('Please Choose Selling Locations') }),
});


const TradeInformationSchema = yup.object().shape({

  // profile: yup.string().when("typeId",{is:'3',then:yup.string().required('Please Select a Product Distributor Profile')
  //     }),
  // speciality: yup.string().when("typeId", {is:'3', then: yup.string().when("profile", {is: "1", then: yup.string().required('Please Select Channel Speciality')})}),
  // Brands:yup.object().when("typeId", {is: (typeId) => (typeId==2 || typeId==3 ),
  //     then:yup.object().shape({
  //     id: yup.number(),
  //     name: yup.string().required(),
  //     doc_id: yup.string(),
  //     documentpath: yup.string(),
  //     isNew: yup.string(),
  //   }).required('Choose a Brand').nullable()
  // }),

  //   Retailers:yup.object().when("startUpType", {is: (typeId) => (typeId=='S_VOLUME'),
  //     then:yup.object().shape({
  //     id: yup.number(),
  //     name: yup.string().required(),
  //     doc_id: yup.string(),
  //     documentpath: yup.string(),
  //     isNew: yup.string(),
  //   }).required('Choose a Brand').nullable()
  // }),

  // Distributor:yup.object().when("startUpType", {is: (typeId) => (typeId=='S_VOLUME'),
  //   then:yup.object().shape({
  //   id: yup.number(),
  //   name: yup.string().required(),
  //   doc_id: yup.string(),
  //   documentpath: yup.string(),
  //   isNew: yup.string(),
  // }).required('Choose Key Distributor').nullable()
  // }),
  // brandList: yup.string().when("typeId", {is: (typeId) => (typeId=='2' || typeId=='3'), then: yup.string().required('Please Choose a Brand')}),
  // retailerList:  yup.string().when("startUpType", {is: (typeId) => (typeId=='S_VOLUME'),then:yup.string().required('Please Choose a Retailer')})
  // .when("typeId", {is: '3', then: yup.string().required('select a retailer (typeId3)')}),
  // DistributorList:  yup.string().when("startUpType", {is: (typeId) => (typeId=='S_VOLUME'),then:yup.string().required('Please Choose a Distributor')}),


  // relevantChannelProfiles:yup.string().when("typeId", {is: (typeId) => (typeId=='2' || typeId=='3'),then: yup.string().required('Please Select Channel Profile')}),
  // productCat:yup.string().required('Please Select a Product Family'),

  // DistributorProfiles:yup.object().when("typeId",{is:'2',then:yup.object().shape({
  //     firstLetter: yup.string().required(),
  //     //groupid: yup.string().required(),
  //     groupname: yup.string().required(),
  //     childid: yup.string().required(),
  //     childname: yup.string().required(),
  //   }).required('Please Select Distributor Profile')
  // }),

  // relevantRetailerProfiles:yup.object().when("typeId",{is:'4',then:yup.object().shape({
  //   // firstLetter: yup.string().required(),
  //   //groupid: yup.string().required(),
  //   groupname: yup.string().required(),
  //   // childid: yup.string().required(),
  //   childname: yup.string().required(),

  // }).required('Please Select Channel Profile')}),

  startUpType: yup.string().when('areYouStartUp', { is: '1', then: yup.string().required('Please Choose Maturity Level') }),
  // no_of_staffs: yup.string().required('Select No.of.Staffs'),
  // annualTurnOver: yup.string().required('Select Annual Turnover'),

  // no_of_skus: yup.number().when("typeId",{is:'2', then:yup.number().required('Please Enter No of SKUs').min(0, 'Sku must have min 0').max(9999, 'sku must have max 4 digits').typeError("Please Enter No of SKUs")}),
  // storeCount: yup.number().when("typeId",{is:'4', then:yup.number().required("Please Enter No of Store's").min(0, 'No of store must have min 0').max(9999, 'sku must have max 4 digits').typeError("Please Enter No of Store's")}),

  no_of_retailers: yup.number().when("startUpType", { is: (typeId) => (typeId == 'S_VOLUME'), then: yup.number().required('Please Enter No of Retailers').min(0, 'Retailer must have min 0').typeError('Please Enter No of Retailers') }),
  no_of_distributors: yup.number().when("startUpType", { is: (typeId) => (typeId == 'S_VOLUME'), then: yup.number().required('Please Enter No of Distributors').min(0, 'Distributor must have min 0').typeError('Please Enter No of Distributors') }),
  // revenue: yup.number().max(100, 'The total of Domestic Revenue and International Revenue should not exceed 100%').nullable(),
  selling: yup.string().when("locationType", { is: 'SELLING', then: yup.string().required('Please Choose Selling Locations') }),
  target: yup.string().when("locationType", { is: 'TARGET', then: yup.string().required('Please Choose Target Locations') }),

});

const TradeLocationSchema = yup.object().shape({
  selling: yup.string().when("locationType", { is: 'SELLING', then: yup.string().required('Please Choose Selling Locations') }),
  target: yup.string().when("locationType", { is: 'TARGET', then: yup.string().required('Please Choose Target Locations') }),
});

const AddProductSchema = yup.object().shape({
  proname: yup.string().required('Enter Product Name').min(3, 'Product Name must have Min 3 Characters').max(255, 'Product Name must have Max 255 Characters'),
  availability: yup.string(),
  noLimit: yup.boolean(),
  ProductCategory: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
    groupid: yup.string().required(),
  }).required('Choose Product Category').nullable(),
  ProductBrand: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required()
  }).required('Choose Product Brand').nullable(),
  videoUrl: yup.string().matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.').transform(value => !value ? null : value).nullable(), //.required('Please provide video Url.')
  tweetPitch: yup.string().required("TweetPitch can't be empty!").min(5, 'TweetPitch must have min 5 Characters').max(280, 'TweetPitch must have max 280 Characters'),
  // productDescription: yup.string().required("product Description can't be empty!").min(5, 'product Description must have min 5 Characters').max(8000, 'product Description must have max 8000 Characters'),
  productshortdec: yup.string().required("product Short Description can't be empty!").min(5, 'product Short Description must have min 5 Characters').max(140, 'product Short Description must have max 140 Characters'),
  productCertification: yup.string().max(255, 'Product Certification must have max 255 Characters'), //.required("Product Certification can't be empty!").min(5, 'Product Certification must have min 5 Characters')
  productWarranty: yup.string().max(255, 'Product Warranty must have max 255 Characters'), //.required("Product Warranty can't be empty!").min(5, 'Product Warranty must have min 5 Characters')
  productLocalisation: yup.string().max(255, 'Product Localisation must have max 255 Characters'), //.required("Product Localisation can't be empty!").min(5, 'Product Localisation must have min 5 Characters')
  review: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      articleurl: yup.string().matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.').transform(value => !value ? null : value).nullable().max( 255, 'Article url should not exceed 255 characters' ).test('len', 'Article url should not exceed 255 characters', val => val?.length != 255)
      ,
      reviewername: yup.string(),
      logo: yup.string()
    })
  ),

  // reviewername: yup.string().when('articleurl',{is: (value)=>value, then: yup.string().required('Please Enter Publication name')}),
  // articleurl: yup.string().when('reviewername',{is: (value)=>value, then: yup.string().required('Please Enter Article Url').matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')}),

  // ratingproofvalidationname: yup.string().when('ratingproofvalidationurl', {is: (value)=>value, then: yup.string().required('Please Enter Rating Site name'), otherwise: yup.string()}),
  // ratingproofvalidationurl: yup.string().when('ratingproofvalidationname', {is: (value)=>value, then: yup.string().required('Please Enter Rating Url').matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.'), otherwise: yup.string()}),
  productexistingrating: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      existproductrating: yup.string(),
      // .required('Please select rating')
      ratingproofvalidationurl: yup.string().matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.').transform(value => !value ? null : value).nullable(),
      ratingproofvalidationname: yup.string(),
      ratingproofvalidationlogo: yup.string()
    })
  ),

  msrp: yup.string().required('Please enter MSRP.').matches(/^[0-9]{1,}([,.][0-9]{1,10})?$/, "Please Enter Valid Price"), //typeError('MSRP must be a number'),
  moq: yup.string().required('Please enter MOQ.'),
  // unit: yup.string().required('Please select unit.'),
  busCurrency: yup.string().required('Please select Business Currency.'),
  moqUnit: yup.string().required('Please select unit.'),
  // max_qty_sample: yup.string().when("no_limit", {
  //   is: false,
  //   then: yup.string().required('Please enter Maximum Qty for Sample Orders.')
  // }),
  productType: yup.string().required('Please Select Product Type'),
  date: yup.date().when('availability', {
    is: 'Available From',
    then: yup.date().required('Please Enter the Date.').typeError('Please Enter Valid Date.'),
  }),
  variantDetails: yup.string().when("productAttributes", { is: '1', then: yup.string().required('Please Enter Product Variant Details.') }),
  //availableFromText: yup.string().when("availability", { is: 'Available From', then: yup.string().required('Please Enter available details') }),

}, [['reviewername', 'articleurl'], ['ratingproofvalidationname', 'ratingproofvalidationurl']]);

const ProductTradeDetailsSchema = yup.object().shape({
  msrp: yup.number().required('Please enter MSRP.').typeError('MSRP must be a number'),
  moq: yup.string().required('Please enter MOQ.'),
  unit: yup.string().required('Please select unit.'),
  busCurrency: yup.string().required('Please select Business Currency.'),
  moqUnit: yup.string().required('Please select unit.'),
  // max_qty_sample: yup.string().when("no_limit", {
  //   is: false,
  //   then: yup.string().required('Please enter Maximum Qty for Sample Orders.')
  // }),
  date: yup.date().when('availability', {
    is: 'Available From',
    then: yup.date().required('Please Enter the Date.').typeError('Please Enter Valid Date.'),
  })
});
const PostYourBuyingNeed = yup.object().shape({
  Categories: yup.string().required('Please Select a Product Category'),
  note: yup.string()
});

const companyProfileBasiEditSchema = yup.object().shape({
  companyLogo: yup.string().required('Please Select companyLogo'),
  companyName: yup.string().required('Please Enter Company Legal Name'),
  country: yup.string().required('Please Select Your country'),
  zipCode: yup.string().required('Please Enter Zip/Postal Code').min(3, 'Zip/Postal Code Must Have Min 3 Characters').max(12, 'Zip/Postal Code Must Have Max 12 Characters'),
  websiteUrl: yup.string().required('Please Enter Company Website URL').matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,63})?(\/.*)?$/, 'Please Enter Valid Url.'),
  tweetPitch: yup.string().required("TweetPitch can't be empty!").min(8
    , 'TweetPitch must have min 8 Characters').max(280, 'TweetPitch must have max 280 Characters'),
  companyDesc: yup.string().min(8, 'Company Description must have min 8 Characters').max(2000, 'The maximum length for the field is 2000 characters'),
  city: yup.string().required('Please Enter Your City.'),
  phoneNumber1: yup.string()
  .test('max-length', 'The maximum length for the field is 30 characters.', function(value) {
    // Check if the value is not empty and its length exceeds 30 characters
    return !(value && value.length > 30);
  })
  .test('min-length', 'Phone number must have minimum 5 characters.', function(value) {
    // Check if the value is not empty and its length is less than 5 characters
    return !(value && value.length > 0 && value.length < 5);
  })
  .test('valid-phone', 'Please Enter a valid Phone Number', function(value) {
    // Check if the value is not empty
    if (value && value.length > 0) {
      // Validate phone number pattern
      const isValidPattern = /^(?!(.*\s){4,})[0-9+ -]*[0-9]+[0-9+ -]*$/.test(value) &&
        /^(?:(?!([0-9])\1{5,}).)*$/.test(value);
      // Validate presence of non-special characters
      const hasNonSpecialChar = !/^[!@#$%^&*()_{}\[\]:;<>,.?~\\|\="']*$/.test(value);
      // Return true if both conditions are met
      return isValidPattern && hasNonSpecialChar;
    }
    // If value is empty or null, no custom validation needed
    return true;
  })
  .nullable(), 
  phoneNumber2: yup.string().min(5, 'Phone number must have minimum 5 characters.').max(30, 'The maximum length for the field is 30 characters.').transform(value => !value ? null : value).nullable(),
  mobile: yup.string().min(5, 'Phone number must have minimum 5 characters.').max(30, 'The maximum length for the field is 30 characters.').transform(value => !value ? null : value).nullable(),
  companyemail: yup.string().email('Please Enter a valid Email Address').transform(value => !value ? null : value).nullable(),

  // insta: yup.lazy(value => !value ? yup.string() : yup.string()
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  // linkedIn: yup.lazy(value => !value ? yup.string() : yup.string()
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),

  insta: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'Instagram handle should have more than 2 characters').max(30, 'Instagram handle should have maximum of 30 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,30}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid Instagram Handle')),
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  twitter: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'Twitter handle should have more than 2 characters').max(15, 'Twitter handle should have maximum of 15 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,15}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid Twitter Handle')),
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  facebook: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'Facebook handle should have more than 2 characters').max(50, 'Facebook handle should have maximum of 50 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid Facebook Handle')),
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  linkedIn: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'LinkedIn handle should have more than 2 characters').max(100, 'LinkedIn handle should have maximum of 100 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,100}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid LinkedIn Handle')),,

  tradeUrls: yup.array().of(yup.object({
    value: yup.lazy(value => !value ? yup.string() : yup.string()
      .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,63})?(\/.*)?$/, 'Please Enter Valid Url.'))
  })),
});

const profileEditSchema = yup.object().shape({
  profile: yup.string().when("typeId", {
    is: '3', then: yup.string().required('Please Select a Product Distributor Profile')
  }),
  speciality: yup.string().when("typeId", { is: '3', then: yup.string().when("profile", { is: "1", then: yup.string().required('Please Select Channel Speciality') }) }),

  relevantChannelProfiles: yup.string().when("typeId", { is: (typeId) => (typeId == '2' || typeId == '3'), then: yup.string().required('Please Select Channel Profile') }),

  relevantRetailerProfiles: yup.object().when("typeId", {
    is: '4', then: yup.object().shape({
      // firstLetter: yup.string().required(),
      //groupid: yup.string().required(),
      groupname: yup.string().required(),
      // childid: yup.string().required(),
      childname: yup.string().required(),

    }).required('Please Select Channel Profile')
  }),

  brandList: yup.string().when("typeId", { is: (typeId) => (typeId == '2' || typeId == '3'), then: yup.string().required('Please Choose a Brand') }),

  productCat: yup.string().required('Please Select a Product Family'),
});

const factProfileSchema = yup.object().shape({
  Yearestablishment: yup.number().required('Please Enter Year').min(1830, 'Year Must be minimum 1830 ').max(currentYear, `year Must be less than or equal to ${currentYear}`).typeError('Please Enter Year'),
  no_of_skus: yup.number().when("typeId", { is: '2', then: yup.number().required('Please Enter No of SKUs').min(0, 'Sku must have min 0').max(9999, 'sku must have max 4 digits').typeError("Please Enter No of SKUs") }).nullable(),
  storeCount: yup.number().when("typeId", { is: '4', then: yup.number().required("Please Enter No of Store's").min(0, 'No of store must have min 0').max(9999, 'sku must have max 4 digits').typeError("Please Enter No of Store's") }).nullable(),
  no_of_staffs: yup.string().required('Select No.of.Staffs'),
  annualTurnOver: yup.string().required('Select Annual Turnover'),
  startUpType: yup.string().when('areYouStartUp', { is: '1', then: yup.string().required('Please Choose Maturity Level') }),
  turnOver: yup.lazy(value => !value ? yup.string() : yup.number().min(0, 'Please enter non-negative values')),
  staffCnt: yup.lazy(value => !value ? yup.string() : yup.number().min(0, 'Please enter non-negative values')),
});

const retailProfileSchema = yup.object().shape({
  Yearestablishment: yup.number().required('Please Enter Year').min(1830, 'Year Must be minimum 1830 ').max(currentYear, `year Must be less than or equal to ${currentYear}`).typeError('Please Enter Year'),
  companyLogo: yup.string().required('Please Select companyLogo'),
  companyName: yup.string().required('Please Enter Company Legal Name'),
  legalAddress: yup.string().required('Please Enter Legal Address'),
  country: yup.string().required('Please Select Your country'),
  zipCode: yup.string().required('Please Enter Zip/Postal Code').min(3, 'Zip/Postal Code Must Have Min 3 Characters').max(12, 'Zip/Postal Code Must Have Max 12 Characters'),
  tweetPitch: yup.string().required("TweetPitch can't be empty!").min(5, 'TweetPitch must have min 5 Characters').max(280, 'TweetPitch must have max 280 Characters'),//.matches(/^[A-Za-z]+$/, "Must be only alphabets"),
  // sourcing: yup.string().min(5, 'Sourcing Priority & Strategy must have min 5 Characters').max(2000, 'Sourcing Priority & Strategy must have max 2000 Characters'),
  companyDesc: yup.string().min(8, 'Company Description must have min 8 Characters').max(2000, 'The maximum length for the field is 2000 characters'),//.matches(/^[A-Za-z]+$/, "Must be only alphabets"),
  // CRN: yup.string().required('Please Enter Company Registration Number').
  //   min(3, 'Company Registration number must have minimum 3 characters.').max(70, 'The maximum length for the field is 70 characters.'), //matches(/^[a-z0-9]+$/i, 'only Alphanumeric characters are allowed.').
  // // VATN : yup.string().matches(/^$|^[a-zA-Z0-9]+$/i, 'only Alphanumeric characters are allowed.').max(70, 'Company Registration number must have maximum 70 Characters.').nullable(),
  VATN: yup.string().when(
    "isVat", {
    is: '1',
    then: yup.lazy(value => !value ? yup.string() : yup.string().min(3, 'VAT number must have minimum 3 characters.').
      matches(/^[a-z0-9]+$/i, 'only Alphanumeric characters are allowed.').max(70, 'The maximum length for the field is 70 characters.')),
    //  yup.string().required('Please Enter VAT Number').min(3, 'VAT number must have minimum 3 characters.').
    // matches(/^[a-z0-9]+$/i, 'only Alphanumeric characters are allowed.').max(70, 'Company Registration number must have maximum 70 Characters.'),
  }
  ),
  city: yup.string().required('Please Enter Your City.'),
  phoneNumber1: yup.string()
  .test('max-length', 'The maximum length for the field is 30 characters.', function(value) {
    // Check if the value is not empty and its length exceeds 30 characters
    return !(value && value.length > 30);
  })
  .test('min-length', 'Phone number must have minimum 5 characters.', function(value) {
    // Check if the value is not empty and its length is less than 5 characters
    return !(value && value.length > 0 && value.length < 5);
  })
  .test('valid-phone', 'Please Enter a valid Phone Number', function(value) {
    // Check if the value is not empty
    if (value && value.length > 0) {
      // Validate phone number pattern
      const isValidPattern = /^(?!(.*\s){4,})[0-9+ -]*[0-9]+[0-9+ -]*$/.test(value) &&
        /^(?:(?!([0-9])\1{5,}).)*$/.test(value);
      // Validate presence of non-special characters
      const hasNonSpecialChar = !/^[!@#$%^&*()_{}\[\]:;<>,.?~\\|\="']*$/.test(value);
      // Return true if both conditions are met
      return isValidPattern && hasNonSpecialChar;
    }
    // If value is empty or null, no custom validation needed
    return true;
  })
  .nullable(), 
   mobile: yup.string().min(5, 'Phone number must have minimum 5 characters.').max(30, 'The maximum length for the field is 30 characters.').transform(value => !value ? null : value).nullable(),
  email: yup.string().email('Please Enter a valid Email Address').transform(value => !value ? null : value).nullable(),

  tradeUrls: yup.array().of(yup.object({
    value: yup.lazy(value => !value ? yup.string() : yup.string()
      .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,63})?(\/.*)?$/, 'Please Enter Valid Url.'))
  })),

  insta: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'Instagram handle should have more than 2 characters').max(30, 'Instagram handle should have maximum of 30 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,30}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid Instagram Handle')),
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  twitter: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'Twitter handle should have more than 2 characters').max(15, 'Twitter handle should have maximum of 15 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,15}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid Twitter Handle')),
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  facebook: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'Facebook handle should have more than 2 characters').max(50, 'Facebook handle should have maximum of 50 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid Facebook Handle')),
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  linkedIn: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'LinkedIn handle should have more than 2 characters').max(100, 'LinkedIn handle should have maximum of 100 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,100}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid LinkedIn Handle')),

  productCat: yup.string().required('Please Select a Product Family'),

  // storeCount: yup.number().required("Please Enter No of Store's").min(0, 'No of store must have min 0').max(9999, 'sku must have max 4 digits').typeError("Please Enter No of Store's").nullable(),

  no_of_staffs: yup.string().required('Select No.of.Staffs'),
  annualTurnOver: yup.string().required('Select Annual Turnover'),
  turnOver: yup.lazy(value => !value ? yup.string() : yup.number().min(0, 'Please enter non-negative values')),
  staffCnt: yup.lazy(value => !value ? yup.string() : yup.number().min(0, 'Please enter non-negative values')),

  relevantRetailerProfiles: yup.object().shape({
    groupname: yup.string().required(),
    childname: yup.string().required(),

  }).required('Please Select Channel Profile'),

  startup: yup.string(),
  productBrand: yup.string().when("startup", { is: '1', then: yup.string().required('select maturity level') }),
  websiteUrl: yup.string().required('Please Enter Company Website URL').matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,63})?(\/.*)?$/, 'Please Enter Valid Url.'),

  selling: yup.string().when("typeId", { is: (typeId) => (typeId == 4 || typeId == 3 || typeId == 5), then: yup.string().required('Please Choose Selling Locations') }),
});

const YourChannelSchema = yup.object().shape({

  // profile: yup.string().when("typeId",{is:'3',then:yup.string().required('Please Select a Product Distributor Profile')
  //     }),
  // speciality: yup.string().when("typeId", {is:'3', then: yup.string().when("profile", {is: "1", then: yup.string().required('Please Select Channel Speciality')})}),
  // Brands:yup.object().when("typeId", {is: (typeId) => (typeId==2 || typeId==3 ),
  //     then:yup.object().shape({
  //     id: yup.number(),
  //     name: yup.string().required(),
  //     doc_id: yup.string(),
  //     documentpath: yup.string(),
  //     isNew: yup.string(),
  //   }).required('Choose a Brand').nullable()
  // }),

  //   Retailers:yup.object().when("startUpType", {is: (typeId) => (typeId=='S_VOLUME'),
  //     then:yup.object().shape({
  //     id: yup.number(),
  //     name: yup.string().required(),
  //     doc_id: yup.string(),
  //     documentpath: yup.string(),
  //     isNew: yup.string(),
  //   }).required('Choose a Brand').nullable()
  // }),

  // Distributor:yup.object().when("startUpType", {is: (typeId) => (typeId=='S_VOLUME'),
  //   then:yup.object().shape({
  //   id: yup.number(),
  //   name: yup.string().required(),
  //   doc_id: yup.string(),
  //   documentpath: yup.string(),
  //   isNew: yup.string(),
  // }).required('Choose Key Distributor').nullable()
  // }),
  // brandList: yup.string().when("typeId", {is: (typeId) => (typeId=='2' || typeId=='3'), then: yup.string().required('Please Choose a Brand')}),
  // retailerList:  yup.string().when("startUpType", {is: (typeId) => (typeId=='S_VOLUME'),then:yup.string().required('Please Choose a Retailer')})
  // .when("typeId", {is: '3', then: yup.string().required('select a retailer (typeId3)')}),
  // DistributorList:  yup.string().when("startUpType", {is: (typeId) => (typeId=='S_VOLUME'),then:yup.string().required('Please Choose a Distributor')}),


  // relevantChannelProfiles:yup.string().when("typeId", {is: (typeId) => (typeId=='2' || typeId=='3'),then: yup.string().required('Please Select Channel Profile')}),
  // productCat:yup.string().required('Please Select a Product Family'),

  // DistributorProfiles:yup.object().when("typeId",{is:'2',then:yup.object().shape({
  //     firstLetter: yup.string().required(),
  //     //groupid: yup.string().required(),
  //     groupname: yup.string().required(),
  //     childid: yup.string().required(),
  //     childname: yup.string().required(),
  //   }).required('Please Select Distributor Profile')
  // }),

  // relevantRetailerProfiles:yup.object().when("typeId",{is:'4',then:yup.object().shape({
  //   // firstLetter: yup.string().required(),
  //   //groupid: yup.string().required(),
  //   groupname: yup.string().required(),
  //   // childid: yup.string().required(),
  //   childname: yup.string().required(),

  // }).required('Please Select Channel Profile')}),

  // startUpType: yup.string().when('areYouStartUp', {is:'1', then:yup.string().required('Please Choose Maturity Level')}),
  // no_of_staffs: yup.string().required('Select No.of.Staffs'),
  // annualTurnOver: yup.string().required('Select Annual Turnover'),

  // no_of_skus: yup.number().when("typeId",{is:'2', then:yup.number().required('Please Enter No of SKUs').min(0, 'Sku must have min 0').max(9999, 'sku must have max 4 digits').typeError("Please Enter No of SKUs")}),
  // storeCount: yup.number().when("typeId",{is:'4', then:yup.number().required("Please Enter No of Store's").min(0, 'No of store must have min 0').max(9999, 'sku must have max 4 digits').typeError("Please Enter No of Store's")}),

 // no_of_retailers: yup.number().when("businessType", { is: (typeId) => (typeId == '1' || typeId == '3'), then: yup.number().required('Please Enter No of Retailers').min(0, 'Retailer must have min 0').typeError('Please Enter No of Retailers') }),
 // no_of_distributors: yup.number().required('Please Enter No of Distributors').min(0, 'Distributor must have min 0').typeError('Please Enter No of Distributors'),
  no_of_resellers: yup.number().when("businessType", { is: (typeId) => (typeId == '2' || typeId == '3'), then: yup.number().required('Please Enter No of Resellers').min(0, 'Reseller must have min 0').typeError('Please Enter No of Resellers') }),
  // revenue: yup.number().max(100, 'The total of Domestic Revenue and International Revenue should not exceed 100%').nullable(),
  relevantChannelProfiles: yup.string().when("businessType", { is: (typeId) => (typeId == '1' || typeId == '3'), then: yup.string().required('Please Select Retailer Profile') }),
  relevantResellerProfiles: yup.string().when("businessType", { is: (typeId) => (typeId == '2' || typeId == '3'), then: yup.string().required('Please Select Reseller Profile') }),
  startUpType: yup.string().when('areYouStartUp', { is: '1', then: yup.string().required('Please Choose Maturity Level') }),

});

const PasswordSchema = yup.object().shape({
  currentPass: yup
    .string()
    .required('Please enter your password').min(8, 'Password Must Have Min 8 Characters').max(25, 'Password Must Have Max 25 Characters')
    .matches(
      /^((?=.*[#?!@$%^&*-]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password must contain at least one uppercase,one lowercase, one number and one special case character'
    ).matches(/^[ A-Za-z0-9#?!@$%^&*-]*$/, 'Password can only contain the following special characters #?!@$%^&*-'),
  newPassword: yup
    .string()
    .required('Please enter your password').min(8, 'Password Must Have Min 8 Characters').max(25, 'Password Must Have Max 25 Characters')
    .matches(
      /^((?=.*[#?!@$%^&*-]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password must contain at least one uppercase, one lowercase, one number and one special case character'
    ).matches(/^[ A-Za-z0-9#?!@$%^&*-]*$/, 'Password can only contain the following special characters #?!@$%^&*-'),
  rePassword: yup.string().required('Confirm password is required').oneOf([yup.ref('newPassword'), null], 'Passwords must match.')
});

const otherUserSchema = yup.object().shape({
  //channelType: yup.string().required('Please Select Channel Type'),
  firstname: yup.string().required('Please Enter FirstName.'),
  lastname: yup.string().required('Please Enter LastName.'),
  //password: yup.string().required('Please Enter Password.'),
  //confirm_password: yup.string().required('Please Enter Confirm password.'),
  mobile: yup.string().required('Please Enter Mobile Number .').min(5, 'Phone number must have minimum 5 characters.').transform(value => !value ? null : value).nullable(),
  emailId: yup.string().required('Please Enter Email Id.').email('Please Enter a valid Email Address').transform(value => !value ? null : value).nullable(),

  yearOfEstablishment: yup.number().required('Please Enter Year').min(1830, 'Year Must be minimum 1830 ').max(currentYear, `year Must be less than or equal to ${currentYear}`).typeError('Please Enter Year'),
  companyLogo: yup.string().required('Please Select companyLogo'),
  companyName: yup.string().required('Please Enter Company Legal Name'),
  country: yup.string().required('Please Select Your country'),
  zipCode: yup.string().required('Please Enter Zip/Postal Code').min(3, 'Zip/Postal Code Must Have Min 3 Characters').max(12, 'Zip/Postal Code Must Have Max 12 Characters'),
  websiteUrl: yup.string().required('Please Enter Company Website URL').matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,63})?(\/.*)?$/, 'Please Enter Valid Url.'),
  tweetPitch: yup.string().required("TweetPitch can't be empty!").min(5, 'TweetPitch must have min 5 Characters').max(280, 'TweetPitch must have max 280 Characters'),
  // sourcing: yup.string().when("typeId", { is: (typeId) => (typeId != '2'), then: yup.string().min(5, 'Sourcing Priority & Strategy must have min 5 Characters').max(5000, 'Sourcing Priority & Strategy must have max 5000 Characters') }),
  companyDesc: yup.string().min(5, 'Company Description must have min 5 Characters').max(2000, 'Company Description must have max 2000 Characters'),
  // CRN: yup.string().required('Please Enter Company Registration Number').
  //   min(3, 'Company Registration number must have minimum 3 characters.').max(70, 'Company Registration number must have maximum 70 Characters.'), //matches(/^[a-z0-9]+$/i, 'only Alphanumeric characters are allowed.').
  // VATN : yup.string().matches(/^$|^[a-zA-Z0-9]+$/i, 'only Alphanumeric characters are allowed.').max(70, 'Company Registration number must have maximum 70 Characters.').nullable(),
  // VATN: yup.string().when(
  //   "isVat", {
  //   is: '1',
  //   then: yup.string().required('Please Enter VAT Number').min(3, 'VAT number must have minimum 3 characters.').
  //     matches(/^[a-z0-9]+$/i, 'only Alphanumeric characters are allowed.').max(70, 'Company Registration number must have maximum 70 Characters.'),
  // }
  // ),
  city: yup.string().required('Please Enter City.'),
  phoneNumber1: yup.string()
  .test('max-length', 'The maximum length for the field is 30 characters.', function(value) {
    // Check if the value is not empty and its length exceeds 30 characters
    return !(value && value.length > 30);
  })
  .test('min-length', 'Phone number must have minimum 5 characters.', function(value) {
    // Check if the value is not empty and its length is less than 5 characters
    return !(value && value.length > 0 && value.length < 5);
  })
  .test('valid-phone', 'Please Enter a valid Phone Number', function(value) {
    // Check if the value is not empty
    if (value && value.length > 0) {
      // Validate phone number pattern
      const isValidPattern = /^(?!(.*\s){4,})[0-9+ -]*[0-9]+[0-9+ -]*$/.test(value) &&
        /^(?:(?!([0-9])\1{5,}).)*$/.test(value);
      // Validate presence of non-special characters
      const hasNonSpecialChar = !/^[!@#$%^&*()_{}\[\]:;<>,.?~\\|\="']*$/.test(value);
      // Return true if both conditions are met
      return isValidPattern && hasNonSpecialChar;
    }
    // If value is empty or null, no custom validation needed
    return true;
  })
  .nullable(),
  //.number().
  // phoneNumber2:yup.string().min(5,'Phone number must have minimum 5 characters.').max(30,'Phone number must have maximum 30 Characters.').transform(value => !value ? null : value).nullable(), 



  insta: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'Instagram handle should have more than 2 characters').max(30, 'Instagram handle should have maximum of 30 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,30}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid Instagram Handle')),
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  twitter: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'Twitter handle should have more than 2 characters').max(15, 'Twitter handle should have maximum of 15 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,15}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid Twitter Handle')),
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  facebook: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'Facebook handle should have more than 2 characters').max(50, 'Facebook handle should have maximum of 50 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid Facebook Handle')),
  // .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Please Enter Valid Url.')),
  linkedIn: yup.lazy(value => !value ? yup.string() : yup.string().min(2, 'LinkedIn handle should have more than 2 characters').max(100, 'LinkedIn handle should have maximum of 100 characters')),
  // matches(/^(?=[a-zA-Z0-9._-]{0,100}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/, 'Please Enter Valid LinkedIn Handle')),

  tradeUrls: yup.array().of(yup.object({
    value: yup.lazy(value => !value ? yup.string() : yup.string()
      .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,63})?(\/.*)?$/, 'Please Enter Valid Url.'))
  })),

  // brandList: yup.string().when("typeId", { is: (typeId) => (typeId == '2' || typeId == '3'), then: yup.string().required('Please Choose a Brand') }),



  // productCat: yup.string().required('Please Select a Product Family'),

  no_of_skus: yup.number().when("typeId", { is: '2', then: yup.number().required('Please Enter No of SKUs').min(0, 'Sku must have min 0').max(9999, 'sku must have max 4 digits').typeError("Please Enter No of SKUs") }).nullable(),
  // storeCount: yup.number().when("typeId",{is:'4', then:yup.number().required("Please Enter No of Store's").min(0, 'No of store must have min 0').max(9999, 'sku must have max 4 digits').typeError("Please Enter No of Store's")}).nullable(),

  // revenue: yup.number().max(100, 'The total of Domestic Revenue and International Revenue should not exceed 100%').nullable(),

  no_of_staffs: yup.string().required('Select No.of.Staffs'),
  annualTurnOver: yup.string().required('Select Annual Turnover'),
  turnOver: yup.lazy(value => !value ? yup.string() : yup.number().min(0, 'Please enter non-negative values')),
  staffCnt: yup.lazy(value => !value ? yup.string() : yup.number().min(0, 'Please enter non-negative values')),

  

});

const ManageProductSchema = yup.object().shape({
  proname: yup.string().required('Enter Product Name').min(3, 'Product Name must have Min 3 Characters').max(255, 'Product Name must have Max 255 Characters'),
  // productCategory: yup.object().shape({
  //   id: yup.number().required(),
  //   name: yup.string().required(),
  //   groupid: yup.string().required(),
  // }).required('Choose Product Category').nullable(),
  // productCategory: yup.string().required('Choose Product Category'),
  // ProductBrand: yup.object().shape({
  //   id: yup.number().required(),
  //   name: yup.string().required()
  // }).required('Choose Product Brand').nullable(),  
  prodImage: yup.string().required('Please Select Image'),
  productUrl: yup.string().required('Enter Product URL').matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, 'Add the url of the product on your web site or any url where you have a full description of the product.').transform(value => !value ? null : value).nullable().max( 255, 'Product url should not exceed 255 characters' ),
  

});
export { InitialprofileSchema, AddFirstProductSchema, UserProfileSchema, companyProfileSchema, TradeInformationSchema, TradeLocationSchema, AddProductSchema, ProductTradeDetailsSchema, PostYourBuyingNeed, companyProfileBasiEditSchema, profileEditSchema, factProfileSchema, companyDistProfileSchema, retailProfileSchema, YourChannelSchema, PasswordSchema, otherUserSchema,ManageProductSchema };
