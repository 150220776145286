import React, { useState, useEffect, useRef } from 'react';
import { Card, CardBody, Row, Col, Container, CardHeader, NavItem, NavLink, Nav, TabContent, TabPane } from "reactstrap";
import { useForm } from "react-hook-form";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { history } from '_helpers';
import { commonService } from '_services/common.service';
import { Spinner } from "views/Hoc/Spinner";
import DistributionChannel from './DistributionChannel';
import { useBeforeFirstRender } from '_hooks/index'
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { FormTextArea, FormInput, FormCheckBox, SingleAutocomplete, NewFieldAutocomplete, FormRadio } from "_components/FormElements/FormInput";
import { yupResolver } from '@hookform/resolvers/yup';
import { ManageProductSchema } from '../profile/validation';
import { PdfCard, ImageCard,ReorderImages } from "views/Hoc/PdfCard";
import MultiUpload from "views/Hoc/MultiUpload";

const ProductManage = (props) => {
  const redux_data = useSelector(state => state.distributionChannel);
  //console.log('redux_data', redux_data);
  let [isLoading, setLoading] = useState(false);
  let [productDetail, setProductDetail] = useState(null);
  let [isAdd, setAdd] = useState((props.location?.pathname === '/admin/Myproductlist/EditProduct' || props.ratingId)||false);
  const [isdata, setData] = useState(true);
  const [tabs, toggleNav] = useState("1");
  let [generalForm, setGeneralForm] = useState(null);
  let [tradeForm, setTradeForm] = useState(null);
  const params = queryString.parse(props.location?.search);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });
  const [activeProductPop, setActiveProductPop] = useState(false);
  const hiddenFileproductPos = useRef(null);
  const hiddenFileproductImg = useRef(null);
  const hiddenFileproductPack = useRef(null);

  let userData = JSON.parse(localStorage.getItem('CHuser'));

  const { register, handleSubmit, control, errors, watch, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(ManageProductSchema),
  });
 // console.log('ManageProductSchema == ', errors);
  const watchAll = watch();
  useEffect(() => {
   // console.log(isAdd, 'productDetail == ', params);
    if (isAdd) {
      getProduct();
    }

    if (!isAdd && productDetail) {
      setData(true);
      //console.log('productDetail', productDetail);
      let brand = {
        name: productDetail?.brand?.brandName,
        id: productDetail?.brand?.id.toString()
      }
      let category = {
        name: productDetail?.productCategory?.productCategorie,
        id: productDetail?.productCategory?.productCategorieId,
        groupid: productDetail?.productCategory?.productGroupId
      }
    }
  }, []);
  const myRef = useRef(null);
  const [mediaState, setMediaState] = useState({
    productImages: [],
    productPos: [],
    packagingImages: [],
    productBrochures: []
  });
  const [error, setError] = useState({
    productImages: null,
    productPos: null,
    packagingImages: null,
    productBrochure: null,
    productUrl:null,
    productBrand: false,
    productCategory:false,
  });
  const [hasBrand, setBrands] = useState({
    length: 0,
    brand: []
  })
  const confirmSavePopup = (value) => {
    setActiveProductPop(false);
    save();
  }
  const [state, setState] = useState({
    status: true,
    proname: '',
    productUrl:'',
    productBrand: '',
    productCategoryOption: [] ,
    productCategory:'',
    productBrandOption: [],
    prodImage:null
  });
  useBeforeFirstRender(() => {
    var userData = JSON.parse(localStorage.getItem('CHuser'));
    //state.productType = userData.businessType.toString();
    (async () => {
      await axios.get('setting/getProductProfile/' + userData?.channelId).then(res => {
        state.productCategoryOption = res.data.productCat;
        state.productBrandOption = res.data.productBrand;
        setState({ ...state });
      }).catch(err => {
        commonService.postService({ err: err.response.data.message, reqUrl: window.location.pathname, service: 'setting/getProductProfile' }, '/error')
      });
    })();
  })

  const handleOnDrop = async (input, data, file_name) => {
    state.prodImage = 1;
   // console.log('hai')
    if(mediaState[input].length>4){
      error[input] = "Maximum 5 files only allowed."; setError({ ...error })
      return
    }

    if (data) {
      const maxSizeInBytes = 5048576;//5Mb
      if (data.size > maxSizeInBytes) {
        error[input] = "Image size exceeds the limit. Please choose a smaller image."; setError({ ...error });
        return
      }
      else{

       // console.log('error ===: ', error);

        error[input] = ""; setError({ ...error });
      }
      tabLoading(true);
     // console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        selectImg(input, reader.result, file_name)
      };
    }
  }
  
  const handleChangeSelect = (input, value) => {
    if (input === 'productCategory') {
      state.productCategory = value
     // console.log('productCategory === : ',value);
      setState({ ...state });
      error.productCategory = false; 
      setError({ ...error });
    } else if (input === 'productBrand') {   
      state.productBrand = value
      setState({ ...state });
      error.productBrand = false; 
      setError({ ...error });
    } 
    if (input !== 'productCategory' && input !== 'productBrand') {
      state[input] = [value]
      setState({ ...state });
    }
  }
  const getBrands = async () => {
    const res = await axios.get('/setting/getChannelBrand').then(res => res.data);
    if (res) {
      if (res?.brandList?.length == 1) {
        setBrands({
          length: 1,
          brand: res.brand[0]
        });
      }
    }
  }
  const handleChangeFileDelete = async (input, fileToBeRemoved) => {
    let filteredState = await mediaState[input].filter(item => item.filename !== fileToBeRemoved);
    if (filteredState) {
      mediaState[input] = filteredState;
      setMediaState({ ...mediaState });
      //console.log('after delete', mediaState);
    }
  }
  const selectImg = async (input, data, file_name) => {
    if (data.length > 0) {
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      let aws_folder = input === 'productImages' ? 'image' : input === 'productPos' ? 'display' : input === 'packagingImages' ? 'packaging' : '';
    try{
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + aws_folder).catch(err => {
        commonService.postService({ err: err.response.data.message, reqUrl: window.location.pathname, service: '/mediaupload/upload/' }, '/error')
      });

      if (resImagePath) {
        if (input === 'productImages') {
          let temp = mediaState.productImages;
          temp.push({ image: resImagePath.data.url, documentUrl: process.env.REACT_APP_CDN_URL_TEMP + resImagePath.data.url, filename: file_name });
          mediaState[input] = temp;
          setMediaState({ ...mediaState });
        } else if (input === 'productPos') {
          let temp = mediaState.productPos;
          temp.push({ image: resImagePath.data.url, documentUrl: process.env.REACT_APP_CDN_URL_TEMP + resImagePath.data.url, filename: file_name });
          mediaState[input] = temp;
          setMediaState({ ...mediaState });
        } else if (input === 'packagingImages') {
          let temp = mediaState.packagingImages;
          temp.push({ image: resImagePath.data.url, documentUrl: process.env.REACT_APP_CDN_URL_TEMP + resImagePath.data.url, filename: file_name });
          mediaState[input] = temp;
          setMediaState({ ...mediaState });
        }
        //console.log('mediaState', mediaState);
        tabLoading(false);
      } else {
        tabLoading(false);
      }
      }catch(err){
        ToastsStore.error('image exceed the desired limit');
        tabLoading(false);
      }
    }
    
  }
  const handleClick = (id) => {
    // debugger
     //console.log("hidden", hiddenFileproductImg);
     if (id === 1) {
       mediaState.productImages.length < 5 ? hiddenFileproductImg.current.click() : error.productImages = "Maximum 5 files only allowed."; setError({ ...error });
     }
     if (id === 2) {
       mediaState.productPos.length < 5 ? hiddenFileproductPos.current.click() : error.productPos = "Maximum 5 files only allowed."; setError({ ...error });
     }
     if (id === 3) {
       mediaState.packagingImages.length < 5 ? hiddenFileproductPack.current.click() : error.packagingImages = "Maximum 5 files only allowed."; setError({ ...error });
     }
   };
   function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  const deleteImg = (e, input) => {
    if (input === 'productPos') {
      error.productPos = null;
      mediaState.productPos = mediaState.productPos.filter(x => x.documentUrl !== e.documentUrl);
      if (e?.productimageid)
        state.deletedproductdisplayimage.push(parseInt(e.productimageid));
    } else if (input === 'packagingImages') {
      error.packagingImages = null;
      mediaState.packagingImages = mediaState.packagingImages.filter(x => x.documentUrl !== e.documentUrl);
      if (e?.productimageid)
        state.deletedproductpackageimage.push(parseInt(e.productimageid));
    } else if (input === 'productImages') {
      error.productImages = null;
      mediaState.productImages = mediaState.productImages.filter(x => x.documentUrl !== e.documentUrl);
      if (e?.productimageid && state.deletedproductimage){
        state.deletedproductimage.push(parseInt(e.productimageid));
      }  
      if(mediaState.productImages.length == 0){
        //console.log('mediaState.productImages == ', mediaState.productImages)
        state.prodImage = '';
      }
      
    }
    setMediaState({ ...mediaState });
    setState({ ...state });
    setError({ ...error });
  }
  const updateImages = (images) => {
    let result=images.map((img,index) => {
      return {productimageid:+img.productimageid,productId:+img.productId,sortorder:index+1,isdefault:index==0?'1':'0'}
    })
   commonService.postService(result, '/Product/sortproductimage')
    
  }

  const handleChangeFileSelect = async (input, value, filename) => {
    if (input === 'productBrochures') {
     // console.log('brochures');
      let temp = mediaState.productBrochures;
      let newFiles = value.filter(file => !mediaState.productBrochures.find(f => f.documentId === file.documentId));

      if (temp.length + newFiles.length <= 5) {
        temp.push(...newFiles);
        mediaState[input] = temp;
        setMediaState({ ...mediaState });
      }
      else {
        console.log('Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb');
      }
    } else {
     // debugger;
      if(filename) {
        let res = await handleOnDrop(input, value, filename);
        if (res) {
          state.prodImage = 1
          //console.log('res', res);
          // mediaState[input]=value;
          // setMediaState({...mediaState})
        };
      }
    }
  }


  const getProduct = async () => {
    setLoading(true);
    setData(true);
   
    let url =  '/Product/viewProduct';
    const res = await commonService.postService({ 'sku':params.sku }, url).catch(err => ToastsStore.error('Something went wrong...'));

     if (res) {
       productDetail = res.data;  
       //console.log('productDetail:', productDetail)
     

      state.productCategory = {
        name: productDetail?.productCategory?.productCategorie,
        id: productDetail?.productCategory?.productCategorieId.toString()
       // groupid: productDetail?.productCategory?.productGroupId.toString()
      };
      setState({ ...state });
      let brand = {
        name: productDetail?.brand?.brandName,
        id: productDetail?.brand?.id.toString()
      } 
    
       mediaState.productImages = [];
       productDetail.productImage.map(x => {
        mediaState.productImages.push({ productId:+x.productId,documentUrl: x?.image?.documentUrl, image: x?.image?.documentPath, imageid: x?.imageid, productimageid: x?.productimageid,sortorder:+x.sortorder,filename: 'PRODUCT', });
        state.prodImage = +x.productId
       });
       state.proname =  productDetail?.product;
       
       state.productBrand = brand;
       state.productUrl = productDetail?.externalURL;
       state.productId = productDetail?.productId;

       setState({ ...state });
       //console.log('productCategory === : ',state);
     }
  //  await getBrands();
    setData(false);
    setLoading(false);
  }
  const toggleNavs = (e, index) => {
    e.preventDefault();
    toggleNav(index);
  }
  const tabLoading = (data) => {
    //console.log('tabLoading', data);
    setLoading(data);
  }
  const [showDrawer, setshowDrawer] = useState(false);
  //console.log("text", showDrawer);
  const closeDrawer = () => {
    setshowDrawer(false);
  }
  
  
  const popUpBeforeSave = async () => {

    (!state.productCategory?.id)? error.productCategory = true:error.productCategory = false; 
    (!state.productBrand?.id)? error.productBrand = true:error.productBrand = false;
    setError({ ...error });

    // if (mediaState?.productImages?.length == 0) {
    //  confirmInactive();
    // }
    // else {
    //   console.log('save part >>');
    if(+state.productBrand?.id > 0  && +state.productBrand?.id > 0)
      save();

    //}
  }



  const save = async () => {
   
    if(+state.productBrand?.id > 0  && +state.productBrand?.id > 0){
    try {
      
      let productBasicInfo = {
        productcategory: +state.productCategory.id,
        productbrand: hasBrand.length == 1 ? +hasBrand.brand : +state.productBrand.id,
        productname: (params?.action == '1')?state.proname:watchAll.proname,
        productUrl: (params?.action == '1')?state.productUrl:watchAll.productUrl,        
        isApproved: state.isApproved,
        approvalType: state.approvalType,
        isAdd:(params?.action == '1')? true : false,
        productId : (params?.action == '1')?state.productId : null
      }
      
      let productimage = [];
      if (mediaState?.productImages?.length > 0) {
        mediaState.productImages.map((x, i) => {
          
        //  if (!x?.imageid)
            productimage.push({ image: x.image, isdefault: i === 0 ? '1' : '0', sortorder: i + 1,imageid: x.imageid, productimageid: x?.productimageid})
        })
      }
      
      let productImages = {       
        productimage: productimage       
      }
      if (productImages?.productimage?.length === 0)
        delete productImages.productimage;
      
      let productPrice = {
        "sampleprice":0
      }
      if (productImages?.productimage?.length === 0)
        delete productImages.productimage;
      
      let product = {
        productBasicInfo: productBasicInfo,
        productImages: productImages,
        productPrice:productPrice
      }

      let deleteList = {}
      if (!isAdd && params?.action == '1') {
        
        deleteList = {        
          deletedproductimage: state.deletedproductimage,
        }        

        if (deleteList?.deletedproductimage?.length === 0)
          delete deleteList.deletedproductimage
        
      } else {

        setLoading(true);
        let res;
    
          let url =  '/Product/manageProduct';
          res = await commonService.postService({ ...product }, url).catch(err => ToastsStore.error('Something went wrong...'));
          
          if (res) {
          //   ToastsStore.error('Product Saved Successfully.')
          //   // let maildata = {
          //   //   name: userData?.firstName + ' ' + userData?.lastName,
          //   //   email: userData?.email,
          //   //   approvalType: res.data.data.approvalType,
          //   //   productName: res.data.data.product,
          //   //   companyName: userData?.companyName
          //   // }
    
          //   // axios.post('mailerservice/productApprovalRequestMail', maildata);          
    
          }
        setLoading(false);
        
          setTimeout(() => ToastsStore.success('Product Saved Successfully.'), 1000);
          history.push('/admin/Myproductlist')
        
        
      }

    }
    catch (err) {
      console.log('err', err);
      ToastsStore.error('Something Went Wrong!');
      setLoading(false);
      tabLoading(false);
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'GeneralForm' }, '/error');
    }
    } else {
      ToastsStore.error('Incomplete form: please fill all fields.');
    }
  }

  const confirmInactive = () => {
    modalPopValue.message = "You haven't added product images so, we are moving your product to inactive. You can add product image later and make it active."
    modalPopValue.title = 'Product Status';
    modalPopValue.value = 'Confirm';
    modalPopValue.popUpId = 2;
    setModalPopValue({ ...modalPopValue });
    setActiveProductPop(true);
  }
  const handleChangePro = input => async e => {    
    //console.log(input,' sssss- ', e.target.value)
    state[input] = e.target.value;
    setState({ ...state });    
  }
  return (
    <>
      {isLoading && <Spinner />}
      <div className="main-content productForm">
        {!props.ratingId && <SimpleHeader location={props.match} />}

        <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
        <Container className="mt-4 col-xl-9 col-lg-11 col-md-12 col-sm-12   container-fluid" fluid>
          <Row>
            <Col lg="12">
              <div style={{ textAlign: "-webkit-center" }}>
                <div className="nav-wrapper" style={{ width: "35%" }}>
                  <Nav
                    className="nav-fill flex-column flex-md-row"
                    id="tabs-icons-text"
                    pills
                    role="tablist"
                  >                    
                  </Nav>
                </div>
              </div>
              <TabContent activeTab={tabs}>
                
                <TabPane tabId="1">
                <form noValidate onSubmit={handleSubmit(popUpBeforeSave)}>
                <Card>
                <CardHeader>
                <h3 style={{ textAlign: 'center' }}>{!isAdd ?'Add':'Edit'} Product</h3>
             
              </CardHeader>
             
              <CardBody  >
                <div className="row">
                  <div className="col-lg-8 col-sm-12">
                    <div className="form-group">
                      <FormInput
                        id="proname"
                        type="text"
                        name="proname"
                        label="Product Name *"
                        onChange={handleChangePro}
                        register={register}
                        defaultValue={state.proname}
                        value={(isAdd)?state.proname:watchAll.proname}
                        error={errors.proname}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-8 col-sm-12">
                    <div className="form-group">
                      <FormInput
                        id="productUrl"
                        type="text"
                        name="productUrl"
                        label="Product URL *"
                       onChange={handleChangePro}
                        register={register}
                        defaultValue={state.productUrl}
                        value={(isAdd)?state.productUrl:watchAll.productUrl}
                        error={errors.productUrl}
                      />
                    </div>
                  </div>
                </div>
               

                <div className="row">
                  <div className="col-lg-6 ">
                    <div className="form-group">
                      <SingleAutocomplete
                        focused={errors.ProductCategory ? true : false}
                        id="productCategory"
                        name="productCategory"
                        options={state.productCategoryOption}
                     //   placeholder="Select Category"
                        disableCloseOnSelect
                        blurOnSelect
                        limitTags={1}
                        control={control}
                        input="productCategory"                        
                        value={state.productCategory}
                        handleChangeSelect={handleChangeSelect}
                        errMessage={'Choose Product\'s Category.'}
                        error={errors.productCategory}
                        defaultValue={state.productCategory}
                        register={register}
                       label="Product Category *"                       
                      />
                       {error.productCategory && <p style={{ color: 'red', float: 'right', textAlign: 'end', width: '100%' }}> {'Product Category *'} </p>}
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="form-group">
                      <SingleAutocomplete
                        // limitTags={2}
                        id="ProductBrand"
                        name="ProductBrand"
                        control={control}
                        register={register}
                        label="Product Brand *"
                        input="productBrand"
                        defaultValue={state.productBrand}
                        value={state.productBrand}
                        // disabled={hasBrand.length == 1 ? true : false}
                        disableCloseOnSelect
                        options={state.productBrandOption}
                        getOptionLabel={(option) => option.name}
                        handleChangeSelect={handleChangeSelect}
                        errMessage={'Choose Product\'s Brand.'}
                        error={errors.ProductBrand}
                      />
                      
                       { (!+state.productBrand?.id && error.productBrand ) && <p style={{ color: 'red', float: 'right', textAlign: 'end', width: '100%' }}> {'Choose Product\'s Brand.'} </p>}
                    </div>
                  </div>
                </div>
                
             
                <div className="col-lg-12 " style={{ display: 'none' }}>
                  <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Product Images</h4>
                  <h5 style={{ float: 'right' }}>(Maximum 5 Images, Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 5 mb)</h5>
                  <div className="form-group mt-3">
                    <MultiUpload multiple={true} limit={5} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="packagingImages" />
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Product Images *</h4>
                  <h5 style={{ float: 'right' }}>(Maximum 5 Images, Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 5 mb)</h5>
                  {!errors.prodImage || state.prodImage ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select Product Images</span>}
                  <span onClick={() => handleClick(1)} onDrop={(e)=>handleOnDrop('productImages', e.dataTransfer.files[0], e.dataTransfer.files[0].name)} >
                    {
                      !mediaState?.productImages?.length ?
                        <div className="form-group mt-3">
                          <div tabindex="0" class="MuiDropzoneArea-root">
                            <div class="MuiDropzoneArea-textContainer">
                              <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">Drag and drop a file here or click</p>
                              <svg class="MuiSvgIcon-root MuiDropzoneArea-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                            </div></div></div> :
                        // <ImageCard list={mediaState.productImages} deleteImg={(e) => deleteImg(e, 'productImages')} />
                        <ReorderImages images={mediaState.productImages} callback={updateImages} deleteImg={(e) => deleteImg(e, 'productImages')} />
                    }
                  </span>
                  {error.productImages && <p style={{ color: 'red', float: 'right', textAlign: 'end', width: '100%' }}> {error.productImages} </p>}
                  <input type="file" accept="image/*" id="hiddenFileproductImg" ref={hiddenFileproductImg} onChange={(e) => handleChangeFileSelect('productImages', e?.target?.files[0], e?.target?.files[0]?.name)} style={{ display: 'none' }} />
                  <input
                          type="hidden"
                          
                          id="prodImage"
                          name="prodImage"
                          ref={register}
                          value={state.prodImage}
                          defaultValue={state.prodImage}
                          
                        />
                </div>
                
               
   


                <div className="text-center mt-4">
                  <button type="submit" className="btn btn-primary">Save</button>
                </div>
              </CardBody>
            </Card>
          </form>
                </TabPane>               
              </TabContent>
            </Col>
          </Row>
          
        </Container>
      </div>
    </>
  );
}

export default ProductManage;