
import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,
  ListGroup,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Badge
} from "reactstrap";
import { userActions } from '_actions/user.actions';
import { Link } from "react-router-dom";
import { history } from '_helpers';
import TopSearch from "../../views/Hoc/TopSearch";
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { dashboardActions } from "_actions/dashboard.actions";
import { commonService } from '_services/common.service';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import axios from 'axios';
import { Divider } from "antd";
//const =class AdminNavbar extends React.Component {
const AdminNavbar = (props) => {
  let userData = JSON.parse(localStorage.getItem('CHuser'));
  if (!localStorage.getItem('fo-auth-token') && !userData) {  
    window.location = '/Login'; 
  }   
  const redux_data = useSelector(state => state.userData);
  let [count, setCount] = useState({ fav_count: 0, cart_count: 0 });
  let [userRedux, setUserRedux] = useState({ firstName: userData.firstName, lastName: userData.lastName, imageUrl: userData.documentUrl });
  let [userStatus, setUserStatus] = useState(0);
  const [previlege, setPrevilege] = useState([]);

  const [advSearchFlag, setAdvSearchFlag] = useState(false);

  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (redux_data?.firstName)
      userRedux.firstName = redux_data.firstName;
    if (redux_data?.lastName)
      userRedux.lastName = redux_data.lastName;
    if (redux_data?.image)
      userRedux.imageUrl = redux_data.image;
    setUserRedux({ ...userRedux });

  }, [redux_data]);
  //let res=props.dashboard_count();        
  const dashboard = useSelector(state => state.dashboard);



  // constructor() {
  //   super();
  //   //localStorage
  //   this.userData = JSON.parse(localStorage.getItem('CHuser'));

  //   console.log('user', this.userData);
  // }
  let toRoute = (userData?.channelTypeId === '4' || userData?.channelTypeId === '5') ? "/admin/companyprofileretres" : (userData?.channelTypeId == '3') ? "/admin/companyprofiledist" : "/admin/profileSettings";

  useEffect(() => {
    const checkUser = async () => {

      let profile_completion_count=userData?.profile_completion_count;
      //let profile_completion_count = await commonService.getServices('/setting/checkUserStatus/' + userData?.channelId);

      // await commonService.getServices('/setting/profileList/' + userData?.channelId + '/' + userData.channelTypeId + '').then(res => {
      //   let pcount = res.data.filter(x => {
      //     return x.isCompleted == true;
      //   })
      // if (pcount.length == res.data.length) {
      //   userStatus = 1;
      //   setUserStatus(userStatus);
      // }
      //})
      if ((userData.channelTypeId == 2 && profile_completion_count == 4) || (userData.channelTypeId != 2 && profile_completion_count == 2)) {
        //console.log("profile_completion_counts:", profile_completion_count.data);
        userStatus = 1;
        setUserStatus(userStatus);
      }
    }
    checkUser();
    getAccessPrevilege();
    props.dashboard_count();
  }, []);

  const getAccessPrevilege = () => {
    (async () => {
      await commonService.getServices('/user/userprevilege/' + userData.contactId).then(res => {
        setPrevilege(res.data.length > 0 ? res.data : []);
      }).catch(err => {
        //ToastsStore.error('No Access Found!');
      });
    })();
  }

  // UNSAFE_componentWillMount()
  // {
  //   console.log("dashcount:",res);

  //   console.log("dashcount:",res);
  // }

  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };

  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };

  const checkUserStatus = async (url) => {

    if (userStatus == 0) {
      let profile_completion_count=userData?.profile_completion_count;
      //let profile_completion_count = await commonService.getServices('/setting/checkUserStatus/' + userData?.channelId);
      if ((userData.channelTypeId == 2 && profile_completion_count == 4) || (userData.channelTypeId != 2 && profile_completion_count == 2)) {
        userStatus = 1;
        setUserStatus(userStatus);
      }
    }
    if (userStatus == 0)
      ToastsStore.warning('Access denied, Complete initial account setup to continue');
    else if (url != 0 && userStatus == 1)
      history.push(url);
    // else if (url == 0 && userStatus == 0) {
    //   console.log("profile_url:", url);
    //   ToastsStore.warning('Access denied, Complete initial account setup to continue');
    //return userStatus == 0 ? true : false;
    //}

    var curentDate = new Date();

    var planExpireAt = new Date(userData.planExpireAt);
    var same = +curentDate.getTime() > +planExpireAt.getTime();
      if(same == true && userData.channelTypeId  == 2 ){
          ToastsStore.error('Your current subscription has been expired, kindly reniwal to enjoy the services');
      } 
  }

  //render() {
  return (
    <>
      <ToastsContainer position="top_right" autoClose={10000} store={ToastsStore} />
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark drk-blue": props.theme === "dark" },
          { "navbar-light bg-secondary": props.theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Form
              className={classnames(
                "navbar-search form-inline mr-sm-3",
                { "navbar-search-light": props.theme === "dark" },
                { "navbar-search-dark": props.theme === "light" }
              )}
            >
              <FormGroup className="mb-0" style={{paddingLeft:"1rem"}}>

{!advSearchFlag &&
                <TopSearch userStatus={userStatus} advSearchFlag={advSearchFlag} setAdvSearchFlag={setAdvSearchFlag} />}

{advSearchFlag &&
                <TopSearch userStatus={userStatus} advSearchFlag={advSearchFlag} setAdvSearchFlag={setAdvSearchFlag} />}
                {/* <InputGroup className="input-group-alternative input-group-merge">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Search Company or Products" type="text" />
                  </InputGroup> */}
              </FormGroup>
              <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={closeSearch}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </Form>

            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: props.sidenavOpen },
                    { "sidenav-toggler-dark": props.theme === "dark" }
                  )}
                  onClick={props.toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              <NavItem className="d-sm-none">
                <NavLink onClick={openSearch}>
                  <i className="ni ni-zoom-split-in" />
                </NavLink>
              </NavItem>

{/* --------------------------notification starts------------------------ */}

              <NavItem >
                <Link className="nav-link"
                 onClick={() => checkUserStatus("/admin/notify")}
                 >
                  <span className="NavIcons">

                  <i className="ni ni-bell-55"></i>

                    { <Badge color="light" className="badge-circle border-red">{dashboard?.dasboard_count ? dashboard?.dasboard_count.not_count : 0}</Badge> }
                  </span>
                </Link>
              </NavItem>

              
{/* --------------------------notification ends------------------------ */}
              <NavItem >
                <Link className="nav-link" onClick={() => checkUserStatus("/admin/favourites")}>
                  <span className="NavIcons">

                    <i class="ni ni-favourite-28"></i>

                    <Badge color="light" className="badge-circle border-white">{dashboard?.dasboard_count ? dashboard?.dasboard_count.fav_count : 0}</Badge>
                  </span>
                </Link>
              </NavItem>
              {/* {userData?.channelTypeId !== '2' && <NavItem>
                <NavLink >
                  <Link className="nav-link" to="/admin/Addcart">
                    <span className="NavIcons">

                      <i class="ni ni-cart"></i>

                      <Badge color="light" className="badge-circle border-white">{dashboard?.dasboard_count ? dashboard?.dasboard_count.cart_count : 0}</Badge>
                    </span>
                  </Link>
                </NavLink>
              </NavItem>} */}
              {/* <NavItem >

                <Link className="nav-link" to="/admin/notification" >
                  <span className="NavIcons">
                    <i className="ni ni-bell-55" />
                    <Badge color="light" className="badge-circle border-white">4</Badge>
                  </span>

                </Link>

              </NavItem> */}


            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center" style={{paddingRight:"1rem"}}>
                    <span className="avatar avatar-sm rounded-circle topicon">
                      <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"); }}
                        alt="..."
                        src={userRedux?.imageUrl ? userRedux?.imageUrl : "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"} //require("assets/img/theme/team-4.jpg")
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block" style={{ cursor: 'pointer' }}>
                      <span className="mb-0 text-sm font-weight-bold">
                        {userRedux?.firstName + ' ' + userRedux?.lastName}
                      </span>
                    </Media>
                   <span className="ml-2 d-none d-lg-block" style={{ cursor: 'pointer' }}><i class="fa fa-bars" aria-hidden="true"></i></span> 
                  </Media>
                </DropdownToggle>

                

                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  {((userData.roleId == 1 || previlege.includes(4)) && userData.isOtherUser == null) &&
                    <DropdownItem>
                      <Link className="menu_dropdown" to={() => userStatus == 1 && {
                        pathname: '/admin/profileform', index: {
                          value: 2, isCompleted: true
                        }
                      }} onClick={() => checkUserStatus('0')}>

                        <i className="ni ni-single-02" />
                        <span>My profile</span></Link>
                    </DropdownItem>}
                  {((userData.roleId == 1 || previlege.includes(2))  && userData.isOtherUser == null ) &&
                    <DropdownItem  >
                      <Link className="menu_dropdown" onClick={() => checkUserStatus(toRoute)}>
                        <i class="far fa-building" /><i class="fas fa-user algn" />
                        <span>Company Profile</span>

                      </Link>

                    </DropdownItem>}
                  {((userData.roleId == 1 || previlege.includes(12))) &&
                    <DropdownItem  >
                      <Link className="menu_dropdown" onClick={() => checkUserStatus("/admin/userManage")}>
                        <i class="fa fa-users" />
                        <span>User Management</span>

                      </Link>

                    </DropdownItem>}
                  {((userData.roleId == 1 || previlege.includes(14))  && userData.isOtherUser == null) &&
                    <DropdownItem  >
                      <Link className="menu_dropdown" onClick={() => checkUserStatus("/admin/settings")}>
                        <i className="ni ni-settings-gear-65" />
                        <span>Settings</span>

                      </Link>

                    </DropdownItem>}

                  {((userData.roleId == 1 || previlege.includes(14))  && userData.isOtherUser == '1') &&
                    <DropdownItem  >
                      <Link className="menu_dropdown" onClick={() => checkUserStatus("/admin/manageUserProfile")}>
                        <i className="ni ni-settings-gear-65" />
                        <span>My Profile</span>

                      </Link>

                    </DropdownItem>}

                    
                  <DropdownItem divider />
                  <DropdownItem
                    href=""
                    onClick={userActions.logout}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>

              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
  //}
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
  theme: "dark"
};

AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"])
};

function mapState(state) {
  const { dasboard_count } = state;
  return { dasboard_count };
}

const actionCreators = {
  dashboard_count: dashboardActions.dashboard_count
};

// const connectedAddgeneralForm = connect(mapState,actionCreators)(AddgeneralForm);
// export { connectedAddgeneralForm as AddgeneralForm };


export default connect(mapState, actionCreators)(AdminNavbar); //AddgeneralForm;

// export default connect(
//   null,
//   { dashboardActions }
// )(AdminNavbar)
//export default AdminNavbar;
