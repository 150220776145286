import React, { useState, useEffect, useRef } from "react";
import { Card, Button, CardHeader, CardBody, Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import { useForm } from "react-hook-form";
import { FormInput, FormCheckBox, FormAutocomplete, FormRadio, FormAutocompleteGrouping, PhoneInput2, FormAutocompleteGroupingRegion,FormAutocompleteGroupingCountries } from "_components/FormElements/FormInput";
import { yupResolver } from '@hookform/resolvers/yup';
import { connect, useSelector } from 'react-redux';
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';
import axios from 'axios';
import { history } from '../../../../_helpers';
import { ModalSpinner } from 'views/Hoc/Spinner';
import { Spinner } from 'views/Hoc/Spinner';
import { UserprofileformSchema } from './validation';
import { Drawer } from 'antd';
import Preference from '../../product/Preference';
import SimpleHeader from "components/Headers/SimpleHeader";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { commonService } from '_services/common.service';
import { FormControl } from "@material-ui/core";
import { Gearspin } from "views/Hoc/Spinner";
import { useDispatch } from 'react-redux';
import { userDataAction } from '_actions/userData.action';
import { PasswordSchema } from '../validation';
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";

var _ = require('lodash');


export const PreferenceForm = (props) => {
  const { closeDrawer, visible, title, contactId} = props;
  return (
    <div className="site-form-in-drawer-wrapper">
      <Drawer title={title} onClose={closeDrawer} visible={visible}>
        <Preference contactId={contactId} visible={visible} />
      </Drawer>
    </div>
  );
};

const Userprofileform = (props) => {
  const [loading, setLoading] = useState(false);
  const [gearLoader, setGearLoader] = useState(false);
  const [showDrawer, setshowDrawer] = useState(false);
  const [checkMail, setCheckMail] = useState(0);
  let [userId, setUserId] = useState(null);
  const { register, handleSubmit, control, errors, watch, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(UserprofileformSchema),
  });

  const { register: register1, handleSubmit: handleSubmit1, control: control1, errors: errors1, watch: watch1, reset } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(PasswordSchema),
  });

  const redux_data = useSelector(state => state.userData);
  console.log('redux_data', redux_data);

  var userData = JSON.parse(localStorage.getItem('CHuser'));
 // console.log('userData', userData);
  let typeId = parseInt(userData?.channelTypeId);
  let isOtherUser = parseInt(userData?.isOtherUser);

  const dispatch = useDispatch();

  // for scrolling to linkedin when clicking from channelDetail contact list
  let myRef = useRef();

  let linkedIn = props.location?.state?.linkedIn ? props.location?.state?.linkedIn : false;
  const{forShareImage, DashsliderFunction} = props;
  let contactId = (props.location?.pathname === "/admin/profileform" || props.ratingId) ? parseInt(userData.contactId) : props?.location?.state ? parseInt(props.location?.state?.contactId) : null;
  let data = useSelector(state => state.adduser);
  let [mail, setMail] = useState(null);
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    reportingTo: '',
    phone: ['', '', ''],
    mobileNo: ['', '', ''],
    empId: '',
    HSContact: '',
    userType: 3,
    companyLogo: null,
    status: true,
    country: '',
    countryList: [],
    linkedinurl: '',
    twitterurl: '',
    isShared: '1',
    isRole: '0',
    documentId: null,
    reportingList: [],
    sellingCountries: [],
    sellingCountriesOption: [],
    targetCountries: [],
    targetCountriesOption: [],
    productValue: [],
    productOptions: [],
    suggestions: '1',
    networkInvitation: '1',

    countryCodemob: '',
    countryCodemob1: '',

    //password
    currentPass: '',
    newPassword: '',
    rePassword: ''

  });

  const watchAll = watch(['firstName', 'lastName', 'mobile', 'phone', 'email', 'jobposition', 'TwitterURL', 'LinkedinURL']);

  const [changePass, setChangePass] = useState(false);

  let watchAll1 = watch1(['currentPass', 'newPassword', 'rePassword']);

  const selectedItem = (gname, check, optionName, optionValue) => {
    let opt = [];
    console.log("check:", check, gname);
    if (check === true) {
      state[optionName].map((item) => {
        console.log("groupname:", item.groupname);
        if (item.groupname === gname || item.status === true) {
          item.status = check;
          opt.push(item);
        }
      });
    }
    if (check === false) {
      console.log("check:", check);
      state[optionName].map((item) => {
        if (item.groupname === gname && state[optionValue].length > 0)
          item.status = check;
      });
      opt = state[optionValue].filter((item) => item.groupname !== gname);
      opt.map(option => option.status = true);
    }
    state[optionValue] = opt;
    setState({ ...state, [optionValue]: state[optionValue] });
    setState({ ...state, [optionName]: state[optionName] });

    if (optionValue === 'sellingCountries' && state.sellingCountries.length === 0)
      errors.selling = true;
    else if (optionValue === 'targetCountries' && state.targetCountries.length === 0)
      errors.target = true;
    else if (optionValue === 'productValue' && state.productValue.length === 0)
      errors.productCat = true;
  }
  useEffect(() => {
    // console.log('props.location.pathname',props.location.pathname);
    // console.log('userData',userData);
    // contactId = props.location.pathname==="/admin/profileform"? parseInt(userData.contactId):props?.location?.state?parseInt(props.location?.state?.contactId):null;
    if (contactId) {
      (async () => {
        await setUserDetail();
        await setTradelocation();
        await setProductFamilies();
      })();
    } else {
      (async () => {
        // await setTradelocation();
        setLoading(true);
        const apiUrl = '/setting/getProductCategories';
        await axios.get(apiUrl).then(res => {
          state.productOptions = res.data.productGroup;
          setState({ ...state });
        }).catch(err => {
          commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getProductCategories' }, '/error');
        });

        await axios.get('setting/getUserProfile/' + userData?.channelId).then(res => {
          let selling = [];
          let target = [];

          state.sellingCountriesOption = res.data.regions1;
          selling = res.data.regions1;
          state.targetCountriesOption = res.data.regions;
          target = res.data.regions;

          setState({ ...state, targetCountriesOption: state.targetCountriesOption });
          setState({ ...state, sellingCountriesOption: state.sellingCountriesOption });

        }).catch(err => {
          setLoading(false);
          commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getUserProfile' }, '/error')
        })

        if (typeId == 2) {
          const apiUrl1 = '/setting/userProduct';
          await axios.get(apiUrl1).then(res => {
            //state.productFamilies=res.data.product;
            state.productValue = res.data.product;

          });
        }

        state.productOptions = state.productOptions.map(x => {
          state.productValue.map(y => {
            if (x.groupid == y.groupid && x.childid == y.childid)
              x.status = true;
          })
          return x;
        })

        await axios.get('common/getCountryList').then(res => {
          state.countryList = res.data.country;
          //setState({...state, countryList:res.data.country});                  
        });

        await axios.get('channel/channelCountry').then(res => {
          state.countryCodemob = res.data.isoCode;
          state.countryCodemob1 = res.data.isoCode;
          let temp = state.countryList.filter(x => x.id == res.data.countryId);
          state.country = temp.length ? temp[0] : null;
        })

        await axios.get('setting/reporting').then(res => {
          state.reportingList = res.data;
        });

        setState({ ...state, productFamilies: state.productFamilies, productValue: state.productValue });
        setLoading(false);
      })();
      // history.push('/admin/dashboard');
    }
  }, []);

  // useEffect(() => {
  //   if (!_.isEmpty(errors) && formState.isSubmitted) {
  //     ToastsStore.error(JSON.stringify(errors));
  //     commonService.postService({ err: JSON.stringify(errors), reqUrl: window.location.pathname, service: 'validation_err' }, '/error')
  //   }
  // }, [errors])
  console.log('state *******', state);

  const closeDrawer = () => {
    setshowDrawer(false);
  }
  const setUserDetail = async () => {
    try {
      setLoading(true);

      dispatch({ type: 'PREFERENCE', data: { checkedKeys: [], checkedKeys1: [] } });

      await axios.post('setting/generalInfo', { contactId: contactId }).then(res => {
        console.log('response', res.data);
        userId = res.data.userId;
        setUserId(userId);
        let country = state.countryList.filter(x => parseInt(x.id) === parseInt(res.data.countryId));
        if (country.length) {
          state.country = country[0];
          state.countryCodemob = state.country.isoCode;
          state.countryCodemob1 = state.country.isoCode;
        }
        let report = state.reportingList.filter(x => parseInt(x.id) === parseInt(res.data.rptContactId));
        if (report.length) {
          state.reportingTo = report[0];
        }
        state.firstName = res.data.firstName;
        state.lastName = res.data.lastName;
        state.isRole = res.data.isMarkettingRole ? res.data.isMarkettingRole : '0';
        props.userDataRedux(res.data.firstName, 'FIRSTNAME');
        props.userDataRedux(res.data.lastName, 'LASTNAME');
        if (res.data.documentPath)
          props.userDataRedux(res.data.documentPath, 'IMAGE');
        else
          props.userDataRedux('http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png', 'IMAGE');
        userData.firstName = res.data.firstName;
        userData.lastName = res.data.lastName;
        userData.documentUrl = res.data.documentPath;
        localStorage.setItem('CHuser', JSON.stringify(userData));
        state.empId = res.data.empId;
        state.HSContact = res.data.hubspotContactId;
        state.email = res.data.email;
        mail = res.data.email
        setMail(mail);
        state.jobTitle = res.data.jobTitle;
        state.linkedinurl = res.data.sociallink ? res.data.sociallink[0]?.contactSocialLink : '';
        state.twitterurl = res.data.sociallink ? res.data.sociallink[1]?.contactSocialLink : '';
        state.phone[1] = res.data.phone1;
        state.phone[0] = res.data.phone1 ? res.data.phone1.replace(/[^A-Z0-9]/ig, "") : '';
        state.mobileNo[1] = res.data.mobileNo;
        state.mobileNo[0] = res.data.mobileNo ? res.data.mobileNo.replace(/[^A-Z0-9]/ig, "") : ''; //.replace('-','');
        state.companyLogo = res.data.documentPath;
        state.contactId = contactId;
        state.documentId = res.data.documentId;
        state.isShared = res.data.isShared;
        state.userType = parseInt(res?.data?.roleId);
        state.status = parseInt(res?.data?.isActive) === 1 ? true : false;
        state.suggestions = res.data.preference.suggestion_comm ? res.data.preference.suggestion_comm : '1';
        state.networkInvitation = res.data.preference.invitation_comm ? res.data.preference.invitation_comm : '1';
      }).catch(err => {
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/generalInfo' }, '/error');
      });

      await axios.get('common/getCountryList').then(res => {
        state.countryList = res.data.country;

      }).catch(err => {
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'common/getCountryList' }, '/error');
      });

      //setting default country id to avoid inconsistencies if contact has countryid it will be updated on general info api call
      await axios.get('channel/channelCountry').then(res => {
        state.countryCodemob = res.data.isoCode;
        state.countryCodemob1 = res.data.isoCode;
        let temp = state.countryList.filter(x => x.id == res.data.countryId);
        state.country = temp.length ? temp[0] : null;
      })

      setState({ ...state });
      setLoading(false);




      await axios.get('setting/reporting').then(res => {
        state.reportingList = res.data;
      }).catch(err => {
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/reporting' }, '/error');
      });
      setState({ ...state });
      setLoading(false);
    }
    catch (err) {
      //ToastsStore.error('User Not Found!');
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setUserDetail' }, '/error');
    }
  }
  const setTradelocation = async () => {
    try {
      let target = []; let selling = [];
      //setLoading(true);
      // await axios.get('setting/tradelocation').then(res => {
      //   target = res.data.targetLocation;
      //   selling = res.data.sellingLocation;
      //   selling.map(x => {
      //     x.status = false;
      //   })
      //   target.map(x => {
      //     x.status = false;
      //   })
      //   state.targetCountriesOption = res.data.targetLocation;
      //   state.sellingCountriesOption = res.data.sellingLocation;
      //   console.log('state *******', state);
      //   setState({ ...state });
      // }).catch(err => {
      //   commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/tradelocation' }, '/error');
      // });

      // selling.map(x => {
      //   x.status = false;
      // })
      // target.map(x => {
      //   x.status = false;
      // })

      await axios.get('setting/getUserProfile/' + userData?.channelId).then(res => {

        state.sellingCountriesOption = res.data.regions1;
        selling = res.data.regions1;
        state.targetCountriesOption = res.data.regions;
        target = res.data.regions;

        setState({ ...state, targetCountriesOption: state.targetCountriesOption });
        setState({ ...state, sellingCountriesOption: state.sellingCountriesOption });

      }).catch(err => {
        setLoading(false);
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getUserProfile' }, '/error')
      })

      await axios.get('setting/getContactLocation/' + contactId).then(res => {
        target = target.map(x => {
          res.data.targetArray.map(y => {
            if (x.groupid == y.groupid && x.childId == y.childId)
              x.status = true;
          })
          return x;
        });

        selling = selling.map(x => {
          res.data.sellingArray.map(y => {
            if (x.groupid == y.groupid && x.childId == y.childId)
              x.status = true;
          })
          return x;
        });
        state.targetCountriesOption = target;
        state.targetCountries = res.data.targetArray.length === 0 ? [] : res.data.targetArray;
        state.sellingCountries = res.data.sellingArray.length === 0 ? [] : res.data.sellingArray;
        state.sellingCountriesOption = selling
        setState({ ...state });
      }).catch(err => {
        setLoading(false);

        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getContactLocation' }, '/error');
      });
    }
    catch (err) {
      setLoading(false);
      //ToastsStore.error('User Location Not Found!');
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setTradelocation' }, '/error');
    }

    // setLoading(false);
  }

  const setProductFamilies = () => {
    try {
      (async () => {
        const apiUrl = '/setting/getProductCategories';
        await axios.get(apiUrl).then(res => {
          state.productOptions = res.data.productGroup;
        }).catch(err => {
          commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getProductCategories' }, '/error');
        });

        await axios.get('/setting/getContactProduct/' + contactId).then(res => {
          console.log('contatc', res.data);
          state.productValue = res.data.productGroup;

        }).catch(err => {
          commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getContactProduct' }, '/error');
        });
        state.productOptions = state.productOptions.map(x => {
          state.productValue.map(y => {
            if (x.groupid == y.groupid && x.childid == y.childid)
              x.status = true;
          })
          return x;
        })
        setState({ ...state });
        //setLoading(false);

        if (linkedIn && myRef) {
          myRef.current.scrollIntoView({ behavior: 'smooth' });
        }

      })();
    }
    catch (err) {
      //ToastsStore.error('User Product Not Found!');
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setProductFamilies' }, '/error');
    }
  }


  const getCompanyLogo = (resImagePath) => {

    setState({ ...state, companyLogo: resImagePath.document.documentUrl, documentId: resImagePath.documentId });

  };



  const handleChange = input => e => {
    if (input === 'status') {
      setState({ ...state, [input]: e.target.checked });
    } else if (input === 'userType') {
      setState({ ...state, [input]: parseInt(e.target.value) });
    } else {
      setState({ ...state, [input]: e.target.value });
    }
  }

  //console.log('watchAll', state, watchAll);

  const handleChangeSelect = (input, value) => {
    console.log(input, value)
    if (input === 'product') {
      state.productOptions.map((item) => {
        if (value.length === 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   if(v.childId!==item.childId)
          //     item.status=false;                 
          //    if(v.childname===item.childname)            
          //     item.status=true;                       

          // });      

          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;

        }
      });

      value.map(option => option.status = true);
      state.productValue = value
      setState({ ...state });

      if (state.productValue.length === 0)
        errors.productCat = true;
    } else if (input === 'sellingCountries') {
      state.sellingCountriesOption.map((item) => {
        if (value.length === 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   if(v.childname!==item.childname)
          //     item.status=false;                 
          //   else if(v.childname===item.childname)            
          //     item.status=true;                       

          // });      
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.sellingCountries = value
      setState({ ...state });

      if (state.sellingCountries.length === 0)
        errors.selling = true;
    } else if (input === 'targetCountries') {
      state.targetCountriesOption.map((item) => {
        if (value.length === 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   if(v.childname!==item.childname)
          //     item.status=false;                 
          //   else if(v.childname===item.childname)            
          //     item.status=true;                       

          // });      
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.targetCountries = value
      setState({ ...state });

      if (state.targetCountries.length === 0)
        errors.target = true;
    } 
    else if (input === 'target') {
        state.targetCountriesOption.map((item) => {
          if (value.length === 0)
            item.status = false;
          else {
            // value.map(v => {          
            //   if(v.childname!==item.childname)
            //     item.status=false;                 
            //   else if(v.childname===item.childname)            
            //     item.status=true;                       

            // });      
            if (value.filter(e => e.childname == item.childname).length)
              item.status = true;
            else
              item.status = false;
          }
        });

        value.map(option => option.status = true);
        state.targetCountries = value
        setState({ ...state });

        if (state.targetCountries.length === 0)
          errors.target = true;
    }
    else if (input === 'country' || input === 'reportingTo' || input == 'phone' || input == 'mobileNo') {
      state[input] = value;
      setState({ ...state });
    } else {
      state[input] = [value];
      setState({ ...state });
    }
  }


  const save = async (e) => {
    // e.preventDefault();
    try {
      setLoading(true);
      let userData = state;
      if ((contactId && (userData.email.trim() !== mail.trim()) || !contactId)) {
        let testMail = contactId ? await commonService.postService({ userId: userId }, 'user/checkusermail/' + userData.email) : await commonService.getServices('account/checkmail/' + userData.email).then(res => {
          return res;
        });

        if (testMail?.data) {
          if (!testMail.data.isavialble) {
            setCheckMail(1);
            setLoading(false);
            return false;
          } else { setCheckMail(0); }
        } else
          setCheckMail(1);
      } else
        setCheckMail(0);

      let trade = state.productValue;
      // let loc = data.trade_loc;
      let sellingLocation = []; let targetLocation = [];

      if (typeId === 4 || typeId === 3 || typeId == 5)
        sellingLocation = state.sellingCountries.map(x => +x.regionCountryJCTId);
      if (typeId === 2)
        targetLocation = state.targetCountries.map(x => +x.regionCountryJCTId);


      let loc = {
        sellingLocation: sellingLocation,
        targetLocation: targetLocation
      }
      let contact = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        documentId: parseInt(userData.documentId),
        countryId: parseInt(userData.country.id),
        isActive: userData.status ? '1' : '0',
        isShared: userData.isShared,
        jobTitle: userData.jobTitle,
        isOtherUser: userData.isOtherUser,
        linkedInUrl: userData.linkedinurl,
        googlePlusUrl: userData.twitterurl,
        phoneNo: userData.phone[2]?.length >= 4 ? userData.phone[1] : null,
        mobileNo: userData?.mobileNo[1],
        role: parseInt(userData.userType),
        rptContactId: userData.userType != 1 ? parseInt(userData.reportingTo?.id) : null,
        workNatureId: 1,
        suggestion_comm: +state.suggestions,
        invitation_comm: +state.networkInvitation,
        ...(typeId == 2 && { isMarkettingRole: state.isRole }),

      };
      let locations = [];

      let productCategories = [];
      //console.log('productValue',trade);
      trade.map(x => {
        let obj = {
          productFamilId: x.groupid,
          productCategoriesId: x.childid
        }
        productCategories.push(obj);
      });

      // let interestedProductCategories = [].push(trade.intProductValue.map(x => ({
      //   productFamilId: x.groupid,
      //   productCategoriesId: x.childid,
      // })));

      let productFamilies = _.uniq(trade.map(x => +x.groupid));
      productFamilies = productFamilies.map(x => ({ id: x }));

      // let interestedProductFamilies = _.uniq(trade.intProductValue.map(x => +x.groupid));
      // interestedProductFamilies = interestedProductFamilies.map(x => ({id: x}));

      if (typeId === 2 && state.isRole == '0')
        locations.push({
          locationType: 'TARGET',
          isUpdate: true,
          location: loc.targetLocation,
        });
      // else if (userData.eventRegisterAs == 1 && typeId === 3)
      //   locations.push({
      //     locationType: 'SELLING',
      //     isUpdate: true,
      //     location: loc.sellingLocation,
      //   });
      // if (typeId === 3)
      //   locations.push({
      //     locationType: 'SELLING',
      //     isUpdate: true,
      //     location: loc.sellingLocation,
      //   });
      // if (typeId === 4)
      //   locations.push({
      //     locationType: 'RETAIL',
      //     isUpdate: true,
      //     location: loc.sellingLocation,
      //   });
      // if (typeId === 5)
      //   locations.push({
      //     locationType: 'RESELLING',
      //     isUpdate: true,
      //     location: loc.sellingLocation,
      //   });

      // console.log("data", data);
      let checkedKeys = [];
      let checkedKeys1 = [];
      let accessPermissions = [];
      if (data?.preference?.checkedKeys)
        checkedKeys = data?.preference?.checkedKeys
      if (data?.preference?.checkedKeys1)
        checkedKeys1 = data?.preference?.checkedKeys1
      if (checkedKeys.length > 0 || checkedKeys1.length > 0) {
        accessPermissions = checkedKeys.map(x => ({ id: +x }));
        accessPermissions = accessPermissions.concat(checkedKeys1.map(x => ({ id: +x })));
      }
    //  console.log('userData ==: ', userData)
      let sendData = {
        channelTypeId: +typeId,
        isOtherUser:isOtherUser,
        contact: contact,
        ...(typeId == 2 && state.isRole == '0' && { locations: locations }),
        productCategories: productCategories,
        productFamilies: productFamilies,
        accessPermissions: accessPermissions,
        ratingId: props.ratingId ? props.ratingId : null
      }
    //  console.log('sendData == ', sendData);
      if (sendData.accessPermissions?.length === 0)
        delete sendData.accessPermissions;
      // if(interestedProductFamilies.length)
      //   sendData = Object.assign(sendData, {interestedProductCategories: interestedProductCategories, interestedProductFamilies: interestedProductFamilies});
      //console.log('savingdata', sendData);    
      if (contactId) {
        
          await axios.put('user/profile/' + contactId + `?noEmailCheck=${false}`, sendData).then(async res => {
          if(res.data.updated) {
            if (props.ratingId && typeId != 2) {

              let pCc = JSON.parse(localStorage.getItem('CHuser'));
              pCc['profile_completion_count'] = '2';
              localStorage.setItem('CHuser', JSON.stringify(pCc));
              setGearLoader(true);
            } else {
              ToastsStore.success('Saved Successfully!');

            }

            if (props.ratingId) {
              await axios.get('algorithm/channel/' + contactId);
            } else {
              axios.get('algorithm/channel/' + contactId);
            }

            if (typeId != 2) {
              await axios.get('algorithm/product/' + contactId);
              //axios.post('mailerservice/Product_Suggestion_Mail', { contactId: user.contactId, channelId: user.channelId });
            }
            //console.log('response', res.data);    

            let path = props.location?.pathname === "/admin/profileform";
            if (path && userData.documentUrl != state.companyLogo) {
              let existing = JSON.parse(localStorage.getItem('CHuser'));
              existing['documentUrl'] = state.companyLogo;
              localStorage.setItem('CHuser', JSON.stringify(existing));
            }

            if (state.companyLogo && props.ratingId) {
              let existing = JSON.parse(localStorage.getItem('CHuser'));
              existing['documentUrl'] = state.companyLogo;
              localStorage.setItem('CHuser', JSON.stringify(existing));
            }

            if (props.ratingId) {
              setLoading(false);
              if(forShareImage == "forShareImage" ){
                let existing = JSON.parse(localStorage.getItem('CHuser'));
                existing['documentUrl'] = state.companyLogo;
                localStorage.setItem('CHuser', JSON.stringify(existing));
                DashsliderFunction();
                return;
              }
              //if (typeId == 2) {
              history.push('/admin/dashboard');
              //}
              // opendialog();
            }


            if (!props.ratingId) {
              await setTradelocation();
              await setUserDetail();
              await setProductFamilies();
              setLoading(false);
            }
          } else {
            ToastsStore.warning('Something Went Wrong!');
            setLoading(false);
          }
          //history.push('admin/profileform');
        }).catch(err => {
          ToastsStore.error('Something Went Wrong!');
          setLoading(false);
          commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'UserEdit' }, '/error');
        });

      } else {
        await axios.post('user/add', sendData).then(res => {
          setLoading(false);
          // Mail Code
          let maildata = {
            to: res.data.created.email,
            name: res.data.created.contact.firstName,// + " " + res.data.created.contact.lastName,
            profileurl: process.env.REACT_APP_LOCALSITE,
            userId: res.data.created.email,
            password: res.data.created.password,
            accountOwner: +res.data.created.contact.rptContactId,
          }
          axios.post('mailerservice/Add_User_Mail', maildata);
          if (props.location?.state?.forEvent)
            history.push('/admin/events/eventManagement/' + props.location?.state?.eventId);//history.push('/admin/events/eventManage');
          else
            history.push('/admin/userManage');
        }).catch(err => console.log('useradderror', err));
      }

    }
    catch (err) {
      console.log("err:", err);
      ToastsStore.error('Something Went Wrong!');
      setLoading(false);
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'UserEdit' }, '/error');
    }

  }
  const revomeProfileImage = () => {
    setState({ ...state, companyLogo: null, documentId: null });
  }

  const valid = () => {
    if (!errors.mobile || state.mobileNo[2].length >= 4)
      return true;
    return 'Mobile No Must Have Min 4 Numbers';
  }

  const valid1 = () => {
    if (!errors.phone || state.phone[2].length >= 4)
      return true;
    return 'Phone No Must Have Min 4 Numbers';
  }

  const updatePassword = async () => {
    setLoading(true);

    let data = {
      password: state.currentPass,
      newPassword: state.newPassword
    }

    await axios.post('/setting/updatePassword', data).then(res => {
      if (res.data.status) {
        ToastsStore.success('Password Updated Successfully!');
        setState({ ...state })
        setChangePass(false);
        reset({ currentPass: '', rePassword: '', newPassword: '' });
      } else {
        ToastsStore.error('Current Password is wrong!');
      }
    }).catch(err => {
      ToastsStore.error('Something went wrong');
      setLoading(false);
    });

    setLoading(false);
  }

  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null });

  const confirmPopup = (res) => {
    setModalPopup(false);
    history.push('/admin/dashboard');
  }
  const closePopup = () => {
    setModalPopup(false);
    history.push('/admin/dashboard');
  };



  const opendialog = (data) => {


    let message = <>
      <p>Congrats your account is now ready to be shared on the platform. <a style={{ textDecoration: 'underline' }} onClick={() => history.push('/admin/PublicProfile/' + userData.channelId)}>Click here</a> to check your public profile. Once completed you can check the suggestion menu to find out matching profiles of {userData?.channelTypeId != 2 ? 'vendors and product' : 'buyers'} . Please note that sugestion list will be visible 5 minutes after profile completion. </p>

    </>

    modalPopValue.message = message;
    modalPopValue.title = 'Note : ';
    modalPopValue.popUpId = 1;
    setModalPopValue({ ...modalPopValue });
    setModalPopup(true);
  }


  return (
    <>
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
      {!props.ratingId && <SimpleHeader location={props.match} />}
      <div className="main-content">
        {loading ? gearLoader ? <Gearspin /> : <Spinner /> : (
          <Container className={ forShareImage === "forShareImage" ? "col-lg-11 col-md-11 col-sm-12 userProfileform"  : "mt-4 col-lg-9 col-md-9 col-sm-12 userProfileform" }  fluid style={forShareImage === "forShareImage" ? {marginTop:"3.5rem", marginBottom:"1rem"} : {}}>
            <form noValidate onSubmit={handleSubmit(save)}>
              {loading && <div style={{ top: '50%', left: '50%', zIndex: '1000', }}><ModalSpinner /></div>}
              <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="typeId" ref={register} name="typeId" value={typeId} control={control} />
              <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="roleId" ref={register} name="roleId" value={state.userType} control={control} />
              <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="isOtherUser" ref={register} name="isOtherUser" value={isOtherUser} control={control} />              
              <Card>
                <CardHeader>
                  <h3 className="text-center mt-4">User Profile</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg="6" md='6' sm='6'>
                      <h3>Your Picture {!errors.companyLogo || state.companyLogo ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select Profile Image</span>}</h3>
                      <Row className="yourpicture">
                        <Col lg="5" sm="12" xs="6" style={{ padding: "0px" }}>
                          <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="companyLogo" name="companyLogo" ref={register} value={state.companyLogo} />
                          <div>
                            <ImageLibrary userLogo={true} getDocumentId={getCompanyLogo} aws_folderName='account-s3' id={2} size={1}
                              img={state.companyLogo ? state.companyLogo : "http://d2maj8ifjo1lti.cloudfront.net/document/default_image/contact.png"} />
                          </div>
                          {/* <h1 onClick={revomeProfileImage} >remove</h1> */}


                        </Col>
                        <Col style={{ paddingTop: "2.5rem" }} lg="7" sm="12" xs="6">
                          <span>Accepts Jpeg / Jpg / Png Only</span>
                          <br></br>
                          <span>(Max size allowed 200 Kb)</span>
                          {(state.documentId && state.documentId != 1) && <div class="d-block mt-1">
                            <i onClick={revomeProfileImage} id="imgr" class="fa fa-trash pointer" aria-hidden="true"></i>
                            <UncontrolledTooltip delay={0} placement="bottom" target="imgr">Remove Picture</UncontrolledTooltip>
                          </div>}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="6" md="6" sm="6">
                      <div className="form-group text-sm mb-0">
                        <h3>User Type <span id='algorithm2'><i class="far fa-handshake didy"></i></span>
                          <UncontrolledTooltip className="handshak" delay={0} placement="bottom" target="algorithm2">This field is included in the matching algorithm</UncontrolledTooltip></h3>
                        {state.userType !== 1 && <FormRadio
                          name="User type"
                          className="text-sm mb-0"

                          options={radioGroupOptions1}
                          control={control}
                          row
                          defaultValue={state.userType}
                          value={state.userType}
                          onChange={handleChange('userType')}
                        />}
                      </div>
                      {state.userType === 1 && <span> Acccount Owner</span>}
                      {state.userType !== 1 && <Row>
                        <Col lg="3" md="4" sm="6" xs="6" style={{ padding: "0px" }}><h3>Status</h3></Col>
                        <Col lg="9" md="8" sm="6" xs="6" style={{ padding: "0" }}>
                          <div className="form-group">
                            <label className="custom-toggle custom-toggle-success">
                              <input defaultChecked checked={state.status} type="checkbox" ref={register} name="status" onChange={handleChange('status')} />
                              <span className="custom-toggle-slider rounded-circle" data-label-off="Inactive" data-label-on="Active"></span>
                            </label>
                          </div>
                        </Col>
                      </Row>}
                    </Col>
                  </Row>

                  {/* { addOrEdit==='EDIT' && <div className="row">
                    <div className="col-lg-6">
                        <FormInput 
                           id="empid"
                           type="text"
                           name="empid"
                           label="Employee ID *"
                           onChange={handleChange('empid')}
                           defaultValue={state.empId}
                           value={watchAll.empid}
                           disabled={true}/>
                    </div>
                    <div className="col-lg-6">
                        <FormInput 
                           id="hscontact"
                           type="text"
                           name="hscontact"
                           label="HS Contact ID *"
                           onChange={handleChange('hscontact')}
                           defaultValue={state.HSContact}
                           value={watchAll.hscontact}
                           disabled={true}
                      />
                    </div>
  </div>} */}

                  <Row>
                    <div className="col-md-6 mt-4">
                      <div className="form-group">
                        <FormInput
                          id="firstName"
                          type="text"
                          name="firstName"
                          label="First Name *"
                          register={register}
                          defaultValue={state.firstName}
                          error={errors.firstName}
                          value={watchAll.firstName}
                          onChange={handleChange('firstName')}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mt-4">
                      <div className="form-group">
                        <FormInput
                          id="lastName"
                          type="text"
                          name="lastName"
                          label="Last Name *"
                          //placeholder="Password"
                          register={register}
                          error={errors.lastName}
                          value={watchAll.lastName}
                          defaultValue={state.lastName}
                          onChange={handleChange('lastName')}
                        />
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-md-6">
                      <div className="form-group">
                        <FormInput
                            InputProps={{
                              readOnly: props?.location?.pathname === "/admin/profileform",
                            }}
                          id="email"
                          type="text"
                          label="Contact Email *"
                          name="email"
                          control={control}
                          register={register}
                          error={errors.email ? errors.email : checkMail ? { message: "Email Id already taken", required: true } : ''}
                          defaultValue={state.email}
                          value={watchAll.email}
                          onChange={handleChange('email')}
                          
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <FormControl variant="outlined" style={{ width: "100%" }}>
                          <FormAutocomplete
                            id="country"
                            name="country"
                            label="Country *"
                            options={state.countryList}
                            placeholder="Select Country"
                            // multiple
                            disableCloseOnSelect
                            blurOnSelect
                            limitTags={1}
                            control={control}
                            input='country'
                            value={state.country}
                            handleChangeSelect={handleChangeSelect}
                            errMessage={'Please Select Country'}
                            error={errors.country}
                            defaultValue={state.country}
                          />
                        </FormControl >
                      </div>
                    </div>
                  </Row>

                  <Row>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">

                        {/* <label className="form-control-label" htmlFor=""> */}
                        {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="phone" ref={register} name="phone"/> */}
                        {/* </label> */}
                        {/* <InputGroup  className="reg reg_12">
  <InputGroupAddon addonType="prepend">
    <InputGroupText></InputGroupText>
  </InputGroupAddon> */}
                        <h3>Contact Phone Number</h3>
                        <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="phone" name="phone" ref={register} value={state.phone[0]} />
                        <PhoneInput2
                          id="phone"
                          label="Contact Phone Number"
                          name="phone"
                          placeholder={'Contact Phone Number'}
                          mobile={state.phone[0]}
                          error={errors.phone}
                          register={register}
                          control={control}
                          // onFocus={(value, country, e, formatted) => setFocused(true)}
                          country_code={state.countryCodemob1 ? state.countryCodemob1 : ''}
                          handleChangeSelect={handleChangeSelect}
                          defaultErrorMessage={(!errors.phone || state.phone[2].length >= 4) ? '' : 'Please Enter Valid No.'}
                          isValid={valid1}
                        />

                        {/* <FormInput
                          className="comp-name12"
                          id="phone"
                          type="text"
                          label="Contact Phone Number"
                          name="phone"
                          register={register}
                          error={errors.phone}
                          defaultValue={state.phone}
                          value={watchAll.phone}
                          onChange={handleChange('phone')}
                        /> */}
                        {/* </InputGroup> */}
                      </div>
                    </div>

                    {/* To scroll when editing linkedin url by clicking icon in channelDetail */}
                    <div style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} ref={myRef} />

                    <div className="col-md-6 mb-3">
                      <div className="form-group">

                        {/* <label className="form-control-label" htmlFor=""> */}
                        {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="mobile" ref={register} name="mobile"/> */}
                        {/* </label> */}
                        {/* <InputGroup  className="reg reg_12">
  <InputGroupAddon addonType="prepend">
    <InputGroupText></InputGroupText>
  </InputGroupAddon> */}

                        <h3>Mobile No *</h3>
                        <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="mobile" name="mobile" ref={register} value={state.mobileNo[0]} />
                        <PhoneInput2
                          id="switchPhone1"
                          label="Mobile No"
                          name="mobileNo"
                          placeholder={'Mobile No *'}
                          mobile={state.mobileNo[0]}
                          error={errors.mobile}
                          register={register}
                          control={control}
                          // onFocus={(value, country, e, formatted) => setFocused(true)}
                          country_code={state.countryCodemob ? state.countryCodemob : ''}
                          handleChangeSelect={handleChangeSelect}
                          defaultErrorMessage={(!errors.mobile || state.mobileNo[2].length >= 4) ? '' : 'Please Enter Valid No.'}
                          isValid={valid}
                        />

                        {/* <FormInput
                          id="mobile"
                          className="comp-name12"
                          type="text"
                          label="Mobile Number *"
                          name="mobile"
                          register={register}
                          error={errors.mobile}
                          defaultValue={state.mobileNo}
                          value={watchAll.mobile}
                          onChange={handleChange('mobileNo')}
                        /> */}
                        {/* </InputGroup> */}
                      </div>
                      {/* <div className="form-group">
          <FormInput
              id="mobile" 
              type="text" 
              label="Mobile Number *" 
              name="mobile" 
              register={register}
              error={errors.mobile}
              defaultValue={state.mobileNo}
              value={watchAll.mobile}
              onChange={handleChange('mobileNo')}
           />
          </div> */}
                    </div>
                  </Row>


                  <Row>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <FormInput
                          id="jobposition"
                          type="text"
                          label="Job Position *"
                          name="jobposition"
                          control={control}
                          register={register}
                          error={errors.jobposition}
                          defaultValue={state.jobTitle}
                          value={watchAll.jobposition}
                          onChange={handleChange('jobTitle')}
                        />
                      </div>
                    </div>

                    {state.userType != '1' && <div className="col-lg-6">
                      <div className="form-group">
                        <FormAutocomplete
                          id="reportingTo"
                          type="text"
                          label="Reporting To"
                          name="reportingTo"
                          options={state.reportingList}
                          disableCloseOnSelect
                          blurOnSelect
                          limitTags={1}
                          control={control}
                          input='reportingTo'
                          value={state.reportingTo}
                          handleChangeSelect={handleChangeSelect}
                          errMessage={'Please Select Reporting Id'}
                          error={errors.reportingTo}
                          defaultValue={state.reportingTo}
                        />

                      </div>
                    </div>}


                    <div className="col-lg-6">
                      <div className="form-group">
                        <FormInput
                          id="LinkedinURL"
                          type="text"
                          label="Linkedin URL"
                          name="LinkedinURL"
                          register={register}
                          error={errors.LinkedinURL}
                          defaultValue={state.linkedinurl}
                          value={watchAll.LinkedinURL}
                          onChange={handleChange('linkedinurl')}
                          autoFocus={linkedIn}
                        />
                      </div>
                    </div>


                    <div className="col-md-6" style={{ display: 'none' }}>
                      <div className="form-group">
                        <FormInput
                          id="TwitterURL"
                          type="text"
                          label="Twitter URL"
                          name="TwitterURL"
                          register={register}
                          error={errors.TwitterURL}
                          defaultValue={state.twitterurl}
                          value={watchAll.TwitterURL}
                          onChange={handleChange('twitterurl')}
                        />
                      </div>
                    </div>
                  </Row>


                  <Col md="6">
                    <h3 style={{ textAlign: 'left' }}> Visibility *</h3>
                    <div className="form-group">
                      <FormRadio
                        name="Visibility"
                        className="text-sm mb-0"

                        options={radioGroupOptions3}
                        control={control}
                        row

                        value={state.isShared}
                        onChange={handleChange('isShared')}
                      />
                    </div>
                  </Col>

                </CardBody>
              </Card>

              {(userData.isOtherUser == null) &&
              <Card>
                <CardHeader>
                  <h3 className="text-center">{userData.channelTypeId == 2 ? "Product Responsibilities" : "Product Interest"}  <span id='algorithm1'><i class="far fa-handshake didy"></i></span>
                    <UncontrolledTooltip className="handshak" delay={0} placement="bottom" target="algorithm1">This field is included in the matching algorithm</UncontrolledTooltip></h3>
                  <p className="text-center">{userData.channelTypeId == 2 ? 'Select the product categories that you are in charge of' : 'Select the products categories that you are interested in'}</p>
                </CardHeader>
                <CardBody>
                  <div className="col-lg-12 ">
                    <div className="form-group">
                      <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="productCat" name="productCat" ref={register} value={state.productValue.length ? state.productValue[0].childname : ''} />
                      <FormAutocompleteGrouping
                        id="ProductResponsibilities"
                        name="ProductResponsibilities"
                        label={userData.channelTypeId == 2 ? "Product Responsibilities" : "Product Interest *"}
                        Options={state.productOptions}
                        optionName='productOptions'
                        optionValue='productValue'
                        placeholder={userData.channelTypeId == 2 ? "Product Responsibilities" : "Product Interest *"}
                        errMessage='Please Select Product'
                        error={!errors.productCat || state.productValue.length ? false : true}
                        defaultValue={state.productValue}
                        value={state.productValue}
                        input="product"
                        selectedItem={selectedItem}
                        handleChangeSelect={handleChangeSelect}
                        control={control}
                        register={register}
                      />
                    </div>
                  </div>

                  {/* {(contactId != userData?.contactId && (typeId === 3 || typeId === 4 || typeId === 5)) && !!(contactId) &&<div onClick={() => { setshowDrawer(true) }} style={{ cursor: "pointer" }}>User Preference</div>}
                  <PreferenceForm title="User Preference" visible={showDrawer} closeDrawer={closeDrawer} contactId={contactId} /> */}


                </CardBody>
              </Card>
              }
              {(typeId === 2) && (
                <Card>
                  <CardHeader>
                    <h3 className="text-center" >{typeId == 3 ? 'Countries of operation' : 'Trade Locations'}  <span id='algorithm'><i class="far fa-handshake didy"></i></span>
                      <UncontrolledTooltip className="handshak" delay={0} placement="bottom" target="algorithm">This field is included in the matching algorithm</UncontrolledTooltip></h3>
                    <p className="text-center" >{typeId == 3 ? 'Update the list of countries you are in charge of' : 'Update the list of countries you are in charge of or you are targetting.'}</p>
                  </CardHeader>
                  <CardBody>
                    {(typeId == 2 && !props.ratingId) &&
                      <Col lg="12">
                        <p> *Note: If you don't have a sales role and you are not in change of any specific country please select the option "I have a marketing role" below</p>
                        <h3 style={{ textAlign: 'left' }}> I have a marketing role</h3>
                        <div className="form-group">
                          <FormCheckBox
                            name="role"
                            id="role"
                            type="checkbox"
                            label="Yes"
                            value={1}
                            register={register}
                            control={control}
                            checked={state.isRole == '1' ? true : false}
                            onClick={handleChange('isRole')}
                          />
                          <FormCheckBox
                            name="role"
                            id="role1"
                            type="checkbox"
                            label="NO"
                            value={0}
                            control={control}
                            register={register}
                            checked={state.isRole == '0' ? true : false}
                            onClick={handleChange('isRole')}
                          />
                          {/* <FormRadio
                          name="role"
                          className="text-sm mb-0"
                          register={register}
                          options={radioGroupOptions2}
                          control={control}
                          row

                          value={state.isRole}
                          defaultValue={state.isRole}
                          onChange={handleChange('isRole')}
                        /> */}
                        </div>
                      </Col>}
                    {/* {(typeId === 3 || typeId === 4 || typeId === 5) && (
                    <div className="col-lg-12 ">
                      <div className="form-group">
                        <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="selling" name="selling" ref={register} value={state.sellingCountries.length ? state.sellingCountries[0].childname : ''} />
                        <FormAutocompleteGrouping
                          input="sellingCountries"
                          id="SellingCountries"
                          label='Selling Locations *'
                          name="SellingCountries"
                          Options={state.sellingCountriesOption}
                          placeholder="Search"
                          errMessage='Please choose Selling Locations'
                          error={!errors.selling || state.sellingCountries.length ? false : true}
                          optionName='sellingCountriesOption'
                          optionValue='sellingCountries'
                          defaultValue={state.sellingCountries}
                          value={state.sellingCountries}
                          // loading={true}
                          selectedItem={selectedItem}
                          handleChangeSelect={handleChangeSelect}
                          control={control}
                          register={register}
                          isGroup='0'
                        />
                      </div>
                    </div>
                  )} */}
                    {(typeId === 2) && state.isRole == '0' && (
                      <div className="col-lg-12 ">
                        <div className="form-group">
                          <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="target" name="target" ref={register} value={state.targetCountries.length ? state.targetCountries[0].childname : ''} />
                          <FormAutocompleteGroupingCountries
                            input="target"
                            id="target"
                            label="Target Locations *"
                            name="target"
                            Options={state.targetCountriesOption}
                            placeholder="Search"
                            errMessage={'Please choose Target Locations'}
                            error={!errors.target || state.targetCountries.length ? false : true}
                            optionName='targetCountriesOption'
                            optionValue='targetCountries'
                            defaultValue={state.targetCountries}
                            value={state.targetCountries}
                            // loading={true}
                            selectedItem={selectedItem}
                            handleChangeSelect={handleChangeSelect}
                            control={control}
                            register={register}
                            isGroup='0'
                          />
                        </div>
                      </div>
                    )}
                    {/* {(contactId != userData?.contactId) && !!(contactId) && <div onClick={() => { setshowDrawer(true) }} style={{ cursor: "pointer" }}>User Preference</div>}
                    <PreferenceForm title="User Preference" visible={showDrawer} closeDrawer={closeDrawer} contactId={contactId} /> */}

                  </CardBody>
                </Card>
              )}

            

              {/* <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="isPreference" ref={register} name="isPreference" value={(contactId != userData?.contactId) ? '1' : ''} control={control} /> */}

              {(contactId != userData?.contactId) &&

                <Card>
                  <CardBody>
                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="preference" ref={register} name="preference" value={(data?.preference?.checkedKeys?.length > 0 || data?.preference?.checkedKeys1?.length > 0) ? 'pass' : ''} control={control} />
                    <Preference contactId={contactId} />

                    {(!errors.preference || (data?.preference?.checkedKeys?.length > 0 || data?.preference?.checkedKeys1?.length > 0)) ? <></> :
                      <span style={{ color: '#f44336', fontSize: '0.75rem', float: 'right' }}>{errors.preference.message}</span>}
                  </CardBody>

                </Card>
              }

              {!!contactId && <Card>
                <CardHeader>
                  <h3 className="text-center mt-4" >Manage Password</h3>
                  <p className="text-center" >Update profile Password</p>
                </CardHeader>
                <CardBody>
                  <Row>

                    {
                      !changePass && <>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <FormInput
                              id="Password"
                              type="password"
                              label="Password *"
                              name="Password"
                              //control={control}
                              //register={register}
                              //error={errors.jobposition}
                              //defaultValue={jobPosition}
                              value="**********"
                              disabled={true}
                            //onChange={handleChange('jobPosition')}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group mt-3">
                            <span className='curs-point' style={{ color: "#30a2e4" }} onClick={() => setChangePass(prev => !prev)}>({!changePass ? 'Change' : 'Cancel'})</span>
                          </div>
                        </div>

                      </>
                    }

                    {changePass &&
                      <>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <FormInput
                              id="Current Password"
                              type="password"
                              label="Current Password *"
                              name="currentPass"
                              control={control1}
                              register={register1}
                              error={errors1.currentPass}
                              defaultValue={state.currentPass}
                              value={watchAll1.currentPass}
                              onChange={handleChange('currentPass')}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <FormInput
                              id="New Password"
                              type="password"
                              label="New Password *"
                              name="newPassword"
                              control={control1}
                              register={register1}
                              error={errors1.newPassword}
                              defaultValue={state.newPassword}
                              value={watchAll1.newPassword}
                              onChange={handleChange('newPassword')}
                            />
                          </div>

                          <div className="form-group">
                            <FormInput
                              id="Confirm Password"
                              type="password"
                              label="Confirm Password *"
                              name="rePassword"
                              control={control1}
                              register={register1}
                              error={errors1.rePassword}
                              defaultValue={state.rePassword}
                              value={watchAll1.rePassword}
                              onChange={handleChange('rePassword')}
                            />
                          </div>
                        </div>
                      </>
                    }
                  </Row>
                  {changePass && <div className="col-lg-12 text-center mt-4">
                    <Button color="warning" onClick={() => setChangePass(prev => !prev)}>
                      Cancel
                    </Button>
                    <Button color="primary" type="submit" onClick={handleSubmit1(updatePassword)}>
                      Submit
                    </Button>
                  </div>}
                </CardBody>
              </Card>}

              <Card style={{ display: 'none' }}>
                <CardHeader>
                  <h3 className="text-center">Communication Preference <span id='algorithm'><i class="far fa-handshake didy"></i></span>
                    <UncontrolledTooltip className="handshak" delay={0} placement="bottom" target="algorithm">This field is included in the matching algorithm</UncontrolledTooltip></h3>
                </CardHeader>
                <CardBody>
                  <div className="row ">
                    1. How frequently do you wish to receive suggestions?
                    <div className="col-sm-12 mt-4">
                      <div className="form-group ">
                        <FormRadio
                          name="CommunicationPreference1"
                          className="text-sm mb-0"
                          //label="How frequently do you wish to receive suggestions?"
                          options={radioGroupOptions}
                          control={control}
                          register={register}
                          row
                          onChange={handleChange('suggestions')}
                          value={state.suggestions}
                          defaultValue={state.suggestions}
                          error={errors.CommunicationPreference1}
                        />
                      </div>
                    </div>
                  </div>


                  {typeId == '2' && <div className="row">
                    2.	How do you want to manage your network invitation coming from vendors and distributors?
                    <div className="col-sm-12 mt-4">
                      <div className="">
                        <FormRadio
                          name="CommunicationPreference2"
                          className=""
                          //label="2.	How do you want to manage your network invitation coming from vendors and distributors? "
                          options={radioGroupOptions}
                          register={register}
                          control={control}
                          row
                          onChange={handleChange('networkInvitation')}
                          value={state.networkInvitation}
                          defaultValue={state.networkInvitation}
                          error={errors.CommunicationPreference2}
                        />
                      </div>
                    </div>
                  </div>}



                </CardBody>
              </Card>
              <div className="col-lg-12 text-center mt-4">
                <Button color="primary" type="submit" style={{ marginBottom: '30px' }}>
                  Save
                </Button>
                {/* <Button color="primary" type="button" href="#">
                   Cancel
                  </Button> */}
              </div>
            </form>
          </Container>
        )}
      </div>
    </>
  );
};

const radioGroupOptions = [
  { value: "0", label: "As they come!" },
  { value: "1", label: "One a  week" },
  { value: "2", label: "Once per 2 weeks " },
  { value: "3", label: "Once per month" },
  { value: "4", label: "Never" }
];
const radioGroupOptions3 = [
  { value: "1", label: "Shared" },
  { value: "0", label: "Confidential" }
];
const radioGroupOptions2 = [
  { value: "1", label: "YES" },
  { value: "0", label: "NO" }
];
const radioGroupOptions1 = [
  { value: 3, label: "Primary" },
  { value: 2, label: "Admin " }
];

// function mapState(state) {
//     const { general_data } = state.adduser;
//     return { general_data };
// }

// const actionCreators = {
//   adduser: adduserActions.adduser
// };

// export default connect(mapState,actionCreators)();


function mapState(state) {
  const general_data = state.userData;
  return general_data;
}

const actionCreators = {
  userDataRedux: userDataAction.userDataRedux
};
export default connect(mapState, actionCreators)(Userprofileform);
