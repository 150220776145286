import React, { useEffect, useState } from 'react';
import { Input, AutoComplete } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Badge, Button } from "reactstrap";
import axios from 'axios';
import { history } from '../../_helpers';
import { Search } from "../pages/Searchlist/Search-Result";
import { PlanFeatureDialog } from "views/Hoc/Dialog/PlanFeatureDialog";
import { userConstants } from '../../../src/_constants';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { commonService } from '_services/common.service';
import { style } from '@mui/system';

const renderTitle = (title, count) => {
  return (
    <span style={{ fontSize: "14px" }}>
      {title}


      {/* <span onClick={() => ShowResults(title)} className="float-right fg-blue pointer">
        See All
      </span> */}


      {/* <Badge color="primary" style={{ fontSize: "12px" }} className="float-right mr-2 ">{count}</Badge> */}
    </span>
  );
};
const ShowResults = (res) => {
  let id;
  if (res === "Vendor")
    id = 11;
  else if (res === "Distributor")
    id = 12;
  else if (res === "Retailer")
    id = 13;
  else if (res === "Reseller")
    id = 14;
  else if (res === "Products")
    id = 5;
  else if (res === "Contact")
    id = 6;

  console.log("searchlist:", id);

  history.replace('/admin/searchResult/' + id);
}

const renderItem = (title, count) => {
  return {
    key: count + title,
    value: title,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          textTransform: 'capitalize'
        }}
      >
        {title}
      </div>
    ),
  };
};
const Channels = ['TELECOM LIFESTYLE FASHION BV', 'PANZERGLASS A/S', 'TELLUR (ABN SYSTEMS INTERNATIONAL SRL)', "THE COOP IDEA LTD", 'CELLY'];
const Products = ['Antibacterial Screenprotector', 'eSmartM2'];
const Vendor = ['PANZERGLASS A/S', 'TELLUR (ABN SYSTEMS INTERNATIONAL SRL)'];
//
const renderOption = (data, title) => {
  let dummyarry = [];
  if (title === 'Vendor') {
    data.map((item, index) => {
      //console.log('render item',renderItem(item,12));
      dummyarry.push(renderItem(item?.companyName.toLowerCase(), index));
    });
  } else if (title === 'Products') {
    data.map((item, index) => {
      //console.log('render item',renderItem(item,12));
      dummyarry.push(renderItem(item.toLowerCase(), index));
    });
  }

  else if (title === 'Distributor') {
    data.map((item, index) => {
      //console.log('render item',renderItem(item,12));
      dummyarry.push(renderItem(item?.companyName.toLowerCase(), index));
    });
  }


  else if (title === 'Retailer') {
    data.map((item, index) => {
      dummyarry.push(renderItem(item?.companyName.toLowerCase(), index));
    });
  }

  else if (title === 'Reseller') {
    data.map((item, index) => {
      dummyarry.push(renderItem(item?.companyName.toLowerCase(), index));
    });
  }

  return dummyarry;
}
let option = [];
let typeId;


const TopSearch = ({ userStatus, setAdvSearchFlag, advSearchFlag }) => {
  const [empty, setEmpty] = useState('');
  const [toastFlag, setToastFlag] = useState(0);
  let [enableStatus, setEnableStatus] = useState();
  const [planUpgrade, setplanUpgrade] = useState(false);

  const [isFreePlan, setFreePlan] = useState(true);


  const [state, setState] = useState({
    options: [],
    company: [],
    product: [],
    type: '',
  });
  const [upgradeType, setUpgradeType] = useState('topSearch');
  //console.log("state.options:", state.options);
  const onSelect = (value, option) => {
    console.log("option:", value);

    let data = state.company.filter(x => x.companyName.toLowerCase() == value);
    if (data.length > 0) {
      console.log("comp:", data);
      // setEmpty('');
      // state.options=[];

      // setState({ ...state, options: state.options,
      // company: [],
      // product: [],
      // type: ''})

      setAdvSearchFlag(!advSearchFlag)
      history.replace('/admin/channel/channelDetail/' + data[0].channelId + '/Search');
    }
    else {
      console.log("product:", state.product);
      let data = state.product.filter(x => x.product.toLowerCase() == value);
      console.log("product:", data);
      setAdvSearchFlag(!advSearchFlag)
     // history.push('/admin/channel/productDetail/' + data[0].productSKU + '/Search');
        if(data[0].externalURL){
          const win = window.open(data[0].externalURL);
          win.focus();
        } 
    }

    //admin/productDetail/SKU000897791
  }

  const handleSearch = async (inputText) => {

    let userData = JSON.parse(localStorage.getItem('CHuser'));
    if (enableStatus == 0) {
      //let userData = JSON.parse(localStorage.getItem('CHuser'));
      let profile_completion_count = userData?.profile_completion_count;
      //let profile_completion_count = await commonService.getServices('/setting/checkUserStatus/' + userData?.channelId);
      if ((userData.channelTypeId == 2 && profile_completion_count == 4) || (userData.channelTypeId != 2 && profile_completion_count == 2)) {
        enableStatus = 1;
        setEnableStatus(enableStatus);
      }
    }

    if (enableStatus == 0 && inputText != '') {
      if (toastFlag == 0)
        ToastsStore.warning('Access denied, Complete initial account setup to continue');
      setToastFlag(1);
    }
    else {
      let featureList = userData.featureList;
      if ((userData.channelTypeId == 2
        //  && featureList[userConstants.ACCESS_TO_DATABASE] == 'yes'
      ) || userData.channelTypeId != 2) {
        setEmpty(inputText);

        await axios.get('channel/topcompany/' + inputText).then(res => {
          option = [];

          state.company = res.data.company;
          state.product = res.data.product;
          if (res.data.type == 2) {
            if (res.data.btype == 1)
              option.push({
                label: renderTitle('Distributor', res.data.company.filter(x => x.channelTypeId == 3).length),
                options: renderOption(res.data.company.filter(x => x.channelTypeId == 3).slice(0, 4), 'Distributor'),
              }, {
                label: renderTitle('Retailer', res.data.company.filter(x => x.channelTypeId == 4).length),
                options: renderOption(res.data.company.filter(x => x.channelTypeId == 4).slice(0, 4), 'Retailer'),
              });
            if (res.data.btype == 2)
              option.push({
                label: renderTitle('Distributor', res.data.company.filter(x => x.channelTypeId == 3).length),
                options: renderOption(res.data.company.filter(x => x.channelTypeId == 3).slice(0, 4), 'Distributor'),
              }, {
                label: renderTitle('Reseller', res.data.company.filter(x => x.channelTypeId == 5).length),
                options: renderOption(res.data.company.filter(x => x.channelTypeId == 5).slice(0, 4), 'Reseller'),
              });
            if (res.data.btype == 3)
              option.push({
                label: renderTitle('Distributor', res.data.company.filter(x => x.channelTypeId == 3).length),
                options: renderOption(res.data.company.filter(x => x.channelTypeId == 3).slice(0, 4), 'Distributor'),
              }, {
                label: renderTitle('Retailer', res.data.company.filter(x => x.channelTypeId == 4).length),
                options: renderOption(res.data.company.filter(x => x.channelTypeId == 4).slice(0, 4), 'Retailer'),
              },
                {
                  label: renderTitle('Reseller', res.data.company.filter(x => x.channelTypeId == 5).length),
                  options: renderOption(res.data.company.filter(x => x.channelTypeId == 5).slice(0, 4), 'Reseller'),
                });

          }
          else if (res.data.type == 3 || res.data.type == 4 || res.data.type == 5) {
            option.push({
              label: renderTitle('Vendor', res.data.company.filter(x => x.channelTypeId == 2).length),
              options: renderOption(res.data.company.filter(x => x.channelTypeId == 2).slice(0, 4), 'Vendor'),
            },
              // {
              //   label: renderTitle('Distributor'),
              //   options: renderOption(res.data.company.filter(x => x.channelTypeId == 3).slice(0, 4), 'Distributor'),
              // }, {
              //   label: renderTitle('Retailer'),
              //   options: renderOption(res.data.company.filter(x => x.channelTypeId == 4).slice(0, 4), 'Retailer'),
              // },
              {
                label: renderTitle('Products', res.data.product.map(x => x.product).length),
                options: renderOption(res.data.product.map(x => x.product), 'Products'),
              });
          }
          // else if (res.data.type == 4) {
          //   option.push({
          //     label: renderTitle('Vendor'),
          //     options: renderOption(res.data.company.filter(x => x.channelTypeId == 2).slice(0, 4), 'Vendor'),
          //   }, 
          //   {
          //     label: renderTitle('Distributor'),
          //     options: renderOption(res.data.company.filter(x => x.channelTypeId == 3).slice(0, 4), 'Distributor'),
          //   },
          //    {
          //     label: renderTitle('Products'),
          //     options: renderOption(res.data.product.map(x => x.product), 'Products'),
          //   });
          // }

        });
        state.options = option;
        setState({ ...state, options: state.options })
      } else {
        setplanUpgrade(true);
      }
    }
  }
  const [Width, setWidth] = useState(300)
  const Seacrhfocus = () => {
    if (!isFreePlan) {
      let width = window.innerWidth;
      document.getElementById('advancedClear').style.opacity = "1";
      console.log('width', width, width >= '1200' && width <= '1340')
      if (width == '1440') {
        document.getElementById('advanced').style.left = "1rem"
        document.getElementById('advancedClear').style.left = "-16rem"
      }
      else if (width >= '1200' && width <= '1340') {

        document.getElementById('advanced').style.left = "1rem"
        document.getElementById('advancedClear').style.left = "-17rem"
      }
      else if (width >= '1024' && width <= '1199') {

        document.getElementById('advanced').style.left = "1rem"
        document.getElementById('advancedClear').style.left = "-16rem"
      }
      else if (width >= '768' && width <= '920') {
        document.getElementById('advancedClear').style.left = "-16rem"

      }
      else if (width >= '320' && width <= '430') {
        document.getElementById('advancedClear').style.left = "10rem"
        document.getElementById('advancedClear').style.position = "absolute"
        document.getElementById('advancedClear').style.top = "5px"

      }
      else {
        document.getElementById('advanced').style.left = "60px"
        document.getElementById('advancedClear').style.left = "-17rem"
      }
    }


  }

  const Seacrhblur = () => {
    if (!isFreePlan) {
      document.getElementById('advancedClear').style.opacity = "0";
      document.getElementById('advanced').style.left = "250px"
      document.getElementById('advancedClear').style.left = "-15px"
    }

    //setWidth(300)
  }

  useEffect(() => {

    let userData = JSON.parse(localStorage.getItem('CHuser'));
    let profile_completion_count = userData?.profile_completion_count;
    let featureList = userData.featureList;
    if ((userData.channelTypeId == 2 && profile_completion_count == 4 && featureList[userConstants.ACCESS_TO_DATABASE] == 'yes') || userData.channelTypeId != 2) {
      setFreePlan(false);
    }
    enableStatus = userStatus;
    setEnableStatus(enableStatus);
  }, [])

  // based on the Subscription List Access to our database of buyers with their company profile

  const accessToDatabase = async () => {

    if (!isFreePlan) {
      let userData = JSON.parse(localStorage.getItem('CHuser'));
      if (enableStatus == 0) {
        //let userData = JSON.parse(localStorage.getItem('CHuser'));
        let profile_completion_count = userData?.profile_completion_count;
        //let profile_completion_count = await commonService.getServices('/setting/checkUserStatus/' + userData?.channelId);
        if ((userData.channelTypeId == 2 && profile_completion_count == 4) || (userData.channelTypeId != 2 && profile_completion_count == 2)) {
          enableStatus = 1;
          setEnableStatus(enableStatus);
        }
        if (enableStatus == 0)
          ToastsStore.warning('Access denied, Complete initial account setup to continue');
        else {
          let featureList = userData.featureList
          if ((userData.channelTypeId == 2
            // && featureList[userConstants.ACCESS_TO_DATABASE] == 'yes'
          ) || userData.channelTypeId != 2) {
            history.push('/admin/advancedsearch');
          } else {
            setplanUpgrade(true);
          }
        }
      }
      else {
        //let user = JSON.parse(localStorage.getItem('CHuser'));
        let featureList = userData.featureList
        if ((userData.channelTypeId == 2
          //  && featureList[userConstants.ACCESS_TO_DATABASE] == 'yes'
        ) || userData.channelTypeId != 2) {
          history.push('/admin/advancedsearch');
        } else {
          console.log("clksearch:", enableStatus);
          setplanUpgrade(true);
        }
      }
    } else {
      setplanUpgrade(true);
    }

  }

  const searchClick = () => {

    let userData = JSON.parse(localStorage.getItem('CHuser'));
    if (userData.channelTypeId == 2 && isFreePlan) {
      let profile_completion_count = userData?.profile_completion_count;
      if (userData.channelTypeId == 2 && profile_completion_count != 4)
        ToastsStore.warning('Access denied, Complete initial account setup to continue');
      else
        setplanUpgrade(true);
    }
  }

  const reset = () => {

    if (!isFreePlan) {
      setAdvSearchFlag(!advSearchFlag)
    }

  }


  const closeplanUpgrade = () => {
    setplanUpgrade(false);
  }
  return (

    <div className="TopSearch">

      <ToastsContainer position="top_right" autoClose={10000} store={ToastsStore} />

      {planUpgrade && <PlanFeatureDialog closeUserView={closeplanUpgrade} UpgradeType={upgradeType} />}

      <AutoComplete
        dropdownClassName="certain-category-search-dropdown"
        dropdownMatchSelectWidth={850}
        style={{
          // width: 300,
        }}
        options={empty == '' ? [] : state.options}
        onSearch={handleSearch}
        onSelect={onSelect}
        onBlur={reset}
        onFocus={searchClick}

      >

        <div>

          <Input.Search onBlur={Seacrhblur}
            onFocus={Seacrhfocus} id="searchbox" className=" d-inline-block" size="large" placeholder="Search Company or Product" />

        </div>

      </AutoComplete>

      <Button
        id="advanced"
        color="secondary"
        type="button"
        className="d-inline-block advanced"
        onClick={e => accessToDatabase()}
      >
        Advanced Search
      </Button>

      <Button
        onClick={e => reset()}
        id="advancedClear"
        color="secondary"
        type="button"
        className="d-inline-block advancedClear"
      >
        <i class="fa fa-times" aria-hidden="true"></i>
      </Button>
    </div>
  )
};

export default TopSearch;