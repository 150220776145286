
import React, { useEffect, useState } from "react";
import { Container, Row, Col, CardBody, Card, CardImg, Nav, NavItem, NavLink, Button } from "reactstrap";
import GuestNavBar from 'views/pages/GuestPage/GuestNavbar/GuestNavbar';
import PubTitle from "views/Hoc/Dialog/PubTitle";
import { history } from '../../../_helpers/history';
import EventChannelListing from "./EventChannelListing";
import EventListingFilter from "./EventListingFilter";
import { CardHeader } from "@material-ui/core";
import Vendorbuyerlist from './Vendorbuyerlist';
import EventListing from "./EventListing";
import { getContainerUtilityClass } from "@mui/system";
import axios from 'axios';
import { commonService } from '_services/common.service';
import { useSelector } from 'react-redux';
import { Spinner } from "views/Hoc/Spinner";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DialogToShowMessage } from "views/Hoc/Dialog/GlobalDialog";

export const MeetingScheduler = ({ eventType, activeTab, eventId, meetId, changeTab, ...props }) => {

    let [isBoFo, setIsBoFo] = useState(false);
    const [allMeet, setAllMeet] = useState(meetId);
    const [activeButton, setActiveButton] = useState(null);
    const [count, setCount] = useState({});
    let [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(true);
    let eventDetails = useSelector(state => state.event)?.eventDetails;
    let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null, cid: null, JctId: null });
    let [isModalPopup, setModalPopup] = useState(false);
  

    useEffect(() => {       

        // console.log("eventDetails::",eventDetails)
        // if(allMeet === 3 && count.myincoming>0)
        //oneventday
        
        // isBoFo = window.location.hostname === process.env.REACT_APP_BOFO;
        // setIsBoFo(isBoFo);
        // if(!isBoFo){
        // setActiveButton(4);
        // setAllMeet(4);
        // }
        // else
        // setActiveButton(1);
        // if (eventDetails.type == 1)
            openCatalyst();
        // else if (eventDetails.type != 1)
            //getCount();

    }, [])

    const getCount = async () => {

        // isBoFo = window.location.hostname === "localhosts";
        // setIsBoFo(isBoFo);

        const userData = JSON.parse(localStorage.getItem('CHuser'));
        let limit;
        let result = await axios.get('channel/eventRequestCount/' + eventId)
            .then(res => {limit=res.data.requestLimit[0]; return res.data.request.filter(x => (x.senderChannelId == userData.channelId || x.receiverChannelId == userData.channelId)) })
            .catch(err => console.log("getcount:", err));
        setCount({
            no_of_meeting_request:+limit.no_of_meeting_request,
            no_of_meeting_allowed:+limit.no_of_meeting_allowed,
            mysubmitted: result?.filter(req => (req.status == 3 && req.senderContactId == userData.contactId)).length,
            myincoming: result?.filter(req => (req.status == 3 && req.receiverContactId == userData.contactId)).length,
            myconfirmed: result?.filter(req => (req.status == 4 && (req.senderContactId == userData.contactId || req.receiverContactId == userData.contactId))).length,
            mydeclained: result?.filter(req => (req.status == 5 && (req.senderContactId == userData.contactId || req.receiverContactId == userData.contactId))).length,

            teamsubmitted: result?.filter(req => (req.status == 3 && (req.senderContactId != userData.contactId && req.receiverContactId != userData.contactId && (req.senderChannelId == userData.channelId)))).length,
            teamincoming: result?.filter(req => (req.status == 3 && (req.receiverChannelId==userData.channelId && req.receiverContactId != userData.contactId))).length, //req.senderContactId != userData.contactId && req.receiverContactId != userData.contactId))).length,
            teamconfirmed: result?.filter(req => (req.status == 4 && req.senderContactId != userData.contactId && req.receiverContactId != userData.contactId)).length,
            teamdeclained: result?.filter(req => (req.status == 5 && (req.senderContactId != userData.contactId && req.receiverContactId != userData.contactId))).length,

        })




        //hide for event day only

        if (isBoFo && result?.filter(req => (req.status == 3 && req.receiverContactId == userData.contactId)).length){
            setAllMeet(3);
            setActiveButton(3);
        }
        else if(isBoFo){
            setAllMeet(meetId);
            setActiveButton(1);
        }

            setLoading(false);

    }

    const eventRegister = () => {
        var userData = JSON.parse(localStorage.getItem('CHuser'));
        return new Promise((resolve) => {
            commonService.getServices('channel/eventRegister/' + userData.contactId).then(res => {
                profileData = res.data;
                setProfileData(profileData)
                console.log('res.data', res.data);
                resolve(true);
            }, err => {
                resolve(false);
                console.log('err', err);
            });
        })
    }

    const getToken = () => {
        let req = {
            url: process.env.REACT_APP_EVENTCATALYST_API + 'oauth/token'
        }
        return commonService.postService(req, 'account/eventcatalystToken').then(res => {
            if (res.data?.error) {
               
                // ToastsStore.warning('Something went wrong'); 
            } else {
                localStorage.setItem('eventcatalyst_token', JSON.stringify(res.data));
            }
            return JSON.stringify(res.data)
        }).catch(err => {
            setLoading(false);
            console.log('err', err)
        })
    }

    const getEventCatalystId = async () => {
        var userData = JSON.parse(localStorage.getItem('CHuser'));
        
           
        
    }
    const openCatalyst = async () => {

        var userData = JSON.parse(localStorage.getItem('CHuser'));
        console.log('eventDetails = : ', eventDetails);
       
        if (!localStorage.getItem('eventcatalyst_token')) {
            await getToken();
        }
        let eventCatalystId = await getEventCatalystId();

        let data = {
            contactId:parseInt(userData.contactId),
            eventId:parseInt(eventDetails.eventId),
            channelId:parseInt(userData.channelId)
        }
        commonService.postService(data, 'account/getPaticipantEventCatalyst').then(async(res) => {
            let responseData = res?.data[0];
            console.log('getPaticipantEventCatalyst = ',responseData)
            if (responseData.isEventCatalyst == '1') {
               
                // ToastsStore.warning('Something went wrong'); 
            
                await eventRegister();
                let dec = profileData.length > 0 ? profileData[0].detailDesc : null
                if ((dec === null) || (dec === '') || !dec) {
                    dec = null;
                } else {
                    dec = dec.toString();
                    dec = dec.replace(/(<([^>]+)>)/ig, '').trim();
                }
                let data = {
                    participantId: parseInt(userData.contactId),
                    eventId: eventDetails.eventCatalystId?eventDetails.eventCatalystId:eventDetails.event_catalyst_id,
                    email:userData.email,
                    typeId:parseInt(userData.channelTypeId),
                    chEventId:eventDetails.id,
                    companyId: parseInt(userData.channelId),
                    existingCategory: profileData.length > 0 ? profileData[0].ExistingCategory ? profileData[0].ExistingCategory.replace(/,/g, "|") : null : null,
                    interestedCategory: profileData.length > 0 ? profileData[0].IntrestedCategory ? profileData[0].IntrestedCategory.replace(/,/g, "|") : null : null,
                    profileType: profileData.length > 0 ? profileData[0].ProfileType : null,
                    retailCustomerProfile: profileData.length > 0 ? profileData[0].Retail_Customer_Profile ? profileData[0].Retail_Customer_Profile.replace(/,/g, "|") : null : null,
                    detailDesc: dec,
                    password: profileData.length > 0 ? profileData[0].password : null
                }

                let body = {
                    token: JSON.parse(localStorage.getItem('eventcatalyst_token')),
                    url: process.env.REACT_APP_EVENTCATALYST_API + 'api/manage',
                    body: data
                }
                commonService.postService(body, 'account/postEventRegister').then(res => {
                    setLoading(false);
                    window.open(res.data.pageUrl, '_blank');
                    changeTab('1');
                }).catch(err => {
                    setLoading(false);
                    changeTab('1');
                    // history.push('events/eventManagement/' + eventDetails.id);
                });

            } else {
                // loading dialog window

                setLoading(false);
                modalPopValue.message = 'Your request is in-Progress. Contact Admin';
                modalPopValue.second_message = '';
                modalPopValue.title = "Event Catalyst Request";
                setModalPopValue({ ...modalPopValue });
                setModalPopup(true);
                
            }
        })    

    }
    const closePopup = () => {
        setModalPopup(false);
        changeTab('1');
      }
    const meetingButtonData = [
        { label: 'Make a meeting request / Available participants' },
        { label: 'Submitted' },
        { label: 'Incoming' },
        { label: 'Confirmed' },
        { label: 'Cancelled/Declined' },
    ]

    const handleButtonClick = (buttonIndex) => {
        setActiveButton(buttonIndex);
        // if(buttonIndex==1){
        // const Meet=0;
        // setAllMeet(Meet)
        // }
        // else if(buttonIndex==2||buttonIndex==3){
        //     const Meet=3;
        // setAllMeet(Meet)
        // }
        // else{
        //     // const Meet=;
        setAllMeet(buttonIndex)
        //}

       
        console.log("buttonIndexs:",buttonIndex,allMeet)
    };
    return (

        <>
            {loading ? <Spinner /> : <>
                <DialogToShowMessage open={isModalPopup} value={modalPopValue}  handleClose={closePopup} />
                {(eventDetails.type == 1 && false) &&
                    <div id='Favourites' className="main-content">

                        <Card>

                            <h3 className="m-3">Your Meetings</h3>

                            <CardBody>
                                {/* <Row className="justify-content-center">
                                    <Button style={{ "margin-bottom": "10px" }} className="btn-sin btn btn-primary" variant="contained" onClick={() => setAllMeet(1)}>Make a meeting request/View full list of participants available for meeting
                                    </Button>
                                </Row> */}
                                <br />
                                {/* <Col>

                                    <Row className="p-2 justify-content-center">
                                        <Col lg="3">
                                        </Col>
                                        <Col lg="2">
                                            <Button className="btn-sin btn btn-primary" variant="contained" onClick={() => setAllMeet(2)}>Submitted</Button>
                                        </Col>
                                        <Col lg="2">
                                            <Button className="btn-sin btn btn-primary" variant="contained" onClick={() => setAllMeet(3)}>Incoming</Button>
                                        </Col>
                                        <Col lg="2">
                                            <Button className="btn-sin btn btn-primary" variant="contained" onClick={() => setAllMeet(4)}>Confirmed</Button>
                                        </Col>
                                        <Col lg="2">
                                            <Button className="btn-sin btn btn-primary" variant="contained" onClick={() => setAllMeet(5)}>Cancelled/Declined</Button>
                                        </Col>
                                    </Row>
                                    <Row className="p-4 border-bottom justify-content-center">
                                        <Col xs lg="3">
                                            <h3>My Meetings</h3>
                                        </Col>
                                        <Col xs lg="2">
                                            {count.mysubmitted}
                                        </Col>
                                        <Col xs lg="2">
                                            {count.myincoming}
                                        </Col>
                                        <Col lg="2">
                                            {count.myconfirmed}
                                        </Col>
                                        <Col xs lg="2">
                                            {count.mydeclained}
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row className="p-3 justify-content-center">
                                        <Col xs lg="3">
                                            <h3>My Team's Meetings</h3>
                                        </Col>
                                        <Col xs lg="2">
                                            {count.teamsubmitted}
                                        </Col>
                                        <Col xs lg="2">
                                            {count.teamincoming}
                                        </Col>
                                        <Col lg="2">
                                            {count.teamconfirmed}
                                        </Col>
                                        <Col xs lg="2">
                                            {count.teamdeclained}
                                        </Col>
                                    </Row>

                                </Col> */}

                                <TableContainer className="meeting-table" component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" >
        {/* <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right"><Button className="btn-sin btn btn-primary" variant="contained" onClick={() => setAllMeet(2)}>Submitted</Button></TableCell>
            <TableCell align="right"><Button className="btn-sin btn btn-primary" variant="contained" onClick={() => setAllMeet(3)}>Incoming</Button></TableCell>
            <TableCell align="right"><Button className="btn-sin btn btn-primary" variant="contained" onClick={() => setAllMeet(4)}>Confirmed</Button></TableCell>
            <TableCell align="right"><Button className="btn-sin btn btn-primary" variant="contained" onClick={() => setAllMeet(5)}>Cancelled/Declined</Button></TableCell>
          </TableRow>
        </TableHead> */}
        <TableHead>
                                            {meetingButtonData.map((meetingData, i) => {
                                                return isBoFo?<TableCell align="right"><Button  key={i + 1} onClick={() => handleButtonClick(i + 1)} className={activeButton === i + 1 ? 'activeBtn' : "btn-sin btn btn-primary"}  variant="contained">{meetingData.label}</Button></TableCell>:
                                                <TableCell align="right"><Button  key={i + 1} onClick={() => handleButtonClick(i + 1)} className={activeButton === i + 1 ? 'activeBtn' : "btn-sin btn btn-primary"} disabled={(i==3)?false:true} variant="contained">{meetingData.label}</Button></TableCell>
                                            })}
                                        </TableHead>

        <TableBody>
            <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}  >
              <TableCell component="th" scope="row" style={{fontWeight:'bold'}}>My Meetings</TableCell>
              <TableCell align="right">{count.mysubmitted}</TableCell>
              <TableCell align="right">{count.myincoming}</TableCell>
              <TableCell align="right">{count.myconfirmed}</TableCell>
              <TableCell align="right">{count.mydeclained}</TableCell>
            </TableRow>
            <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}  >
              <TableCell component="th" scope="row" style={{fontWeight:'bold'}}>My Team's Meetings</TableCell>
              <TableCell align="right">{count.teamsubmitted}</TableCell>
              <TableCell align="right">{count.teamincoming}</TableCell>
              <TableCell align="right">{count.teamconfirmed}</TableCell>
              <TableCell align="right">{count.teamdeclained}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>


                            </CardBody>
                        </Card>

                      



                        {allMeet === 1 &&

                            <Card>
                                <CardBody>
                                    <Vendorbuyerlist id={props.id} eventId={eventId} count={count} type={eventType} getCount={getCount} meetId={allMeet} />
                                </CardBody>
                            </Card>}

                        {((allMeet === 3 && count.myincoming) || (allMeet === 2 && count.mysubmitted) || (allMeet === 5 && count.mydeclained) || (allMeet === 4 && count.myconfirmed)) &&

                            <Row className="justify-content-center">
                                <Col style={{ backgroundColor: "#f8f9fe" }} lg="9">
                                    <Card>
                                        <CardBody>
                                            <EventListing count={count} type={props.id === 'buyers' ? 'buyer' : 'vendor'} eventId={eventId} status={allMeet} getCount={getCount} eventType={eventType} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>}
                    </div>}
            </>}
        </>
    );
}

export default MeetingScheduler;