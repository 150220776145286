import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Container } from "reactstrap";
import emealogo from 'assets/img/guestpage/LOGO_CHANNEL_SUMMIT_EMEA.png'
import ifalogo from 'assets/img/guestpage/Logo_Global_Retail_IFA.png'
import apaclogo from 'assets/img/guestpage/Logo_ChannelConnect APAC.png'

import EventTabs1 from './EventTabs1';
import EventTabsDynamic from './EventTabsDynamic';
// import RegisterEve from './RegisterEve';
import Vendorbuyerlist from './Vendorbuyerlist';
import { ManageUser } from "views/pages/Events/ManageUser";
import SimpleHeader from "components/Headers/SimpleHeader";
import Eventtravel from './Eventtravel';
// import { useSelector } from 'react-redux';
import EventAgenda from './EventAgenda';
import { MeetingScheduler } from './MeetingScheduler';
// import { CodeOutlined } from '@material-ui/icons';
// import EventMeetings from './EventMeetings';
import MySchedule from './MySchedule';
// import EventAwards from './EventAwards';
import { Fallback } from '_components/ErrorElements/FallBack';
import axios from 'axios';
import { Spinner } from "views/Hoc/Spinner";
import { useDispatch } from "react-redux";
import Moment from 'moment';
import { DialogToShowMessage } from "views/Hoc/Dialog/GlobalDialog";
import { useSelector } from 'react-redux';

const EventManagement = (props) => {

    const dispatch = useDispatch();
    const [message, setMessage] = useState({ msg: '', isShow: false });
    let [eventDetails, setEventDetails] = useState();
    let [loading, setLoading] = useState(true);
    const userData = JSON.parse(localStorage.getItem('CHuser'));

    // let eventDetails = useSelector(state => state.event)?.eventDetails;
    let eventPackage = useSelector(state => state.event)?.eventPackage;
    const getEventDetails = async () => {

        await axios.get('/channelAdmin/getEventById/' + props.match['params'].id).then(res => {
            if (res.data.length) {

                eventDetails = { ...res.data[0], eventId: res.data[0].id };
                // let pack = eventDetails.channel?.package;
                //code changed @16/8
                // let packDetails = {};
                // if (eventDetails.type == 1 && pack) {
                //     packDetails = {
                //         eventId: eventDetails.eventId,
                //         packageId: pack.packageId,
                //         price: pack.price,                       
                //         additionalMembersCosting: pack.additionalMembersCosting,
                //         membersAllowed: pack.no_of_access_badge,
                //         packageName: pack.packageName,
                //         additionalMembers: eventDetails.channel?.additionalMembers ? eventDetails.channel?.additionalMembers : 0,
                //         marketingOptions: pack.marketingOptions,
                //         packageOptions: pack.packageOptions
                //     };
                // }
                setEventDetails(eventDetails)
                // dispatch({ type: 'EVENT_PACK', data: { ...packDetails } });
                dispatch({ type: 'EVENT', data: { ...eventDetails } })
                setLoading(false);
            }
        })
    }

    const checkAgenda = async () => {
        await axios.get('/channel/checkAgenda/' + props.match['params'].id).then(res => {
            if (res.data > 0) {
                setActiveTab('7');
            }

        })
    }

    const checkAgenda1 = async (id) => {
        // return await axios.get('/channel/checkAgenda/' + props.match['params'].id).then(res => {
        //if (res.data > 0)
        setActiveTab(id);
        // else{
        //     setMessage({ msg: 'Make sure to save the Agenda page before proceeding to the next step', isShow: true });
        //     setActiveTab('4');
        // }


        // })
    }

    useEffect(() => {
        //checkAgenda();
        //if(userData.channelTypeId==2)
        //setActiveTab('7');
        getEventDetails();

    }, [])


    const getTabs = (id) => {
        switch (id) {
            //after dynamic tabs implementation
            case '1':
                return <ManageUser />;
            case '2':
                return <EventAgenda eventId={eventDetails?.eventId} contactId={userData.contactId} eventType={eventDetails?.type} />
            case '3':
                return <MeetingScheduler changeTab={changeTab} id={userData.channelTypeId != 2 ? 'vendors' : 'buyers'} eventId={eventDetails?.eventId} eventType={eventDetails?.type} meetId={1} />
            case '4':
                return <MySchedule id={userData.channelTypeId != 2 ? 'vendors' : 'buyers'} eventId={eventDetails?.eventId} eventType={eventDetails?.type} />
            case '5':
                return <Eventtravel eventId={eventDetails?.eventId} />;
            case '6':
                return <Vendorbuyerlist id={(userData.channelTypeId != 2 && eventPackage?.isSeller == '0') ? 'vendors' : 'buyers'} buyerList={eventDetails.buyers} sellerList={eventDetails.sellers} eventId={eventDetails?.eventId} eventType={eventDetails?.type} showBuyerMeeting={eventDetails?.showBuyerMeeting} />
            // case '1':
            //     return <ManageUser />;
            // case '2':
            //     return <Eventtravel eventId={eventDetails?.eventId} />;
            // case '3':
            //     //temp work for current event only
            //     return <Vendorbuyerlist id={(userData.channelTypeId != 2 && eventPackage?.isSeller == '0') ? 'vendors' : 'buyers'} buyerList={eventDetails.buyers} sellerList={eventDetails.sellers} eventId={eventDetails?.eventId} eventType={eventDetails?.type} showBuyerMeeting={eventDetails?.showBuyerMeeting} />
            // case '4':
            //     return <EventAgenda eventId={eventDetails?.eventId} contactId={userData.contactId} eventType={eventDetails?.type} />
            // // case '5':
            // //     return <EventMeetings />
            // // case '6':
            // //     return <EventAwards />
            // case '7':
            //     return <MeetingScheduler changeTab={changeTab} id={userData.channelTypeId != 2 ? 'vendors' : 'buyers'} eventId={eventDetails?.eventId} eventType={eventDetails?.type} meetId={1} />
            // case '8':
            //     return <MySchedule id={userData.channelTypeId != 2 ? 'vendors' : 'buyers'} eventId={eventDetails?.eventId} eventType={eventDetails?.type} />
            // default:
            //     return eventDetails?.eventStatus == 3 ? <Vendorbuyerlist id={userData.channelTypeId != 2 ? 'vendors' : 'buyers'} eventId={eventDetails?.eventId} eventType={eventDetails?.type} showBuyerMeeting={eventDetails?.showBuyerMeeting} /> : <ManageUser />
        }
    }

    const [activeTab, setActiveTab] = useState('1');

    const changeTab = (id) => {

        let isBoFo = window.location.hostname === process.env.REACT_APP_BOFO;


        if (id == 3 || id == 8 || id == 7 || id == 6) {
            let listingDate = (userData.channelTypeId == 2 || eventPackage?.isSeller == '1') ? eventDetails.vendorListDate : eventDetails.buyerListDate;
            let listingMessage = (userData.channelTypeId == 2 || eventPackage?.isSeller == '1') ? eventDetails.vendorListMsg : eventDetails.buyerListMsg;
            let schedulerDate = (userData.channelTypeId == 2 || eventPackage?.isSeller == '1') ? eventDetails.vendorMeetingDate : eventDetails.buyerMeetingDate;
            let schedulerMessage = (userData.channelTypeId == 2 || eventPackage?.isSeller == '1') ? eventDetails.vendorSchedulerMsg : eventDetails.buyerSchedulerMsg;

            if (!isBoFo && (id == 8 || id == 7 || id == 6) &&  (Moment(listingDate).format('YYYY-MM-DD') > Moment(new Date()).format('YYYY-MM-DD'))) {
                setMessage({ msg: listingMessage, isShow: true });
            } else if (!isBoFo && id == 3 && (Moment(schedulerDate).format('YYYY-MM-DD') > Moment(new Date()).format('YYYY-MM-DD'))) {
                setMessage({ msg: schedulerMessage, isShow: true });
            } else {
                checkAgenda1(id);
            }
        } else {
            // checkAgenda1(id);
            setActiveTab(id);
        }
    }


    const errorHandler = (error, errorInfo) => {
        console.log('Logging:', error, errorInfo);

    }



    return (
        <>
            {loading ? <Spinner /> : <>
                <DialogToShowMessage open={message.isShow} value={{ title: eventDetails.name, message: message.msg, second_message: '' }}  handleClose={() => setMessage({ msg: '', isShow: false })} />

                {/* <ConfirmDialog open={message.isShow} value={{ title: eventDetails.event_name, message: message.msg, second_message: '' }} handleSave={() => setMessage({ msg: '', isShow: false })} handleClose={() => setMessage({ msg: '', isShow: false })} /> */}


                <SimpleHeader location={props.match} />
                <div id="listing" className="main-content">
                    {eventDetails?.eventId == 3 &&
                        <div className='text-center'>
                            <img src={emealogo} width={130} alt='' />
                            <h1 className='text-center mt-3 mb-3'>Channel Summit Details</h1>
                        </div>}

                    {eventDetails?.eventId == 4 &&
                        <div className='text-center'>
                            <img src={ifalogo} width={130} />
                            <h1 className='text-center mt-3 mb-3'>Global Retail Connect at IFA</h1>
                        </div>}
                    {eventDetails?.eventId == 5 &&
                        <div className='text-center'>
                            <img src={apaclogo} width={130} />
                            <h1 className='text-center mt-3 mb-3'>Channel Connect 1-to-1 EMEA-APAC</h1>
                        </div>}

                    {/* {userData.channelTypeId != 2  &&
                    <p className='text-center mt-3 mb-3' style={{ fontSize: '13px' }}><b>IMPORTANT:</b> The vendor list is for review only, not for sending meeting requests.</p>} */}
                    {/* : <p className='text-center mt-3 mb-3' style={{ fontSize: '13px' }}><b>IMPORTANT:</b> The buyer list is for review only, not for sending meeting requests. The meeting scheduler will open on May 2nd. <br />In the meantime you can create various lists and save your selection using the "Favorite" icon</p> */}
                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                        <Container className="col-12 list-tab" fluid>


                            {/* <EventTabs1 activeTab={activeTab} eventDetails={eventDetails} changeTab={changeTab} channelTypeId={userData.channelTypeId} isBuyer={eventPackage?.isBuyer} isSeller={eventPackage?.isSeller} /> */}

                            <EventTabsDynamic activeTab={activeTab} eventDetails={eventDetails} changeTab={changeTab} channelTypeId={userData.channelTypeId} isBuyer={eventPackage?.isBuyer} isSeller={eventPackage?.isSeller} />

                            {getTabs(activeTab)}

                            {/* <RegisterEve/> */}
                            {/* <ManageUser/> */}
                            {/* <Vendorbuyerlist/> */}
                            {/* <Eventtravel/> */}
                        </Container>
                    </ErrorBoundary>
                </div></>}
        </>
    );
};

export default EventManagement;